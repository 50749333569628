import { useEffect, useState } from "react";
import PropTypes from "prop-types";

const CircularProgress = ({ score, size = 100, strokeWidth = 10 }) => {
  const [currentScore, setCurrentScore] = useState(0);

  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const progress = (currentScore / 100) * circumference;

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentScore(score);
    }, 100);
    return () => clearTimeout(timer);
  }, [score]);

  return (
    <div
      className="circular-progress"
      style={{ width: size, height: size }}
    >
      <svg width={size} height={size}>
        <circle
          className="circle-background"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <circle
          className="circle-progress"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={circumference - progress}
        />
      </svg>
      <div
        className="score"
        style={{
          fontSize: size / 5.8,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {currentScore}%
      </div>
    </div>
  );
};

CircularProgress.propTypes = {
  score: PropTypes.number.isRequired,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
};

export default CircularProgress;
