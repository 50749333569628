import { useState } from "react";
import PropTypes from 'prop-types';
import { MdClear } from "react-icons/md";
import { InputText } from "primereact/inputtext";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useJDInfo } from "../../../contextProviders/JDInfoContext";

function JDInitialScreeingQAsPage(props){
    const {goToPreviousTab, goToNextTab} = props;
    const defaultQuestions = [
        { question: '' },
        { question: '' },
        { question: '' },
    ];

    const { getJDInfo, changeJDInfo } = useJDInfo();
    const jdInfo = getJDInfo();


    const [questions, setQuestions] = useState(jdInfo?.initialScreeningQAs && jdInfo?.initialScreeningQAs?.length !== 0 ? 
        jdInfo?.initialScreeningQAs : defaultQuestions);

    const addQuestion = () => setQuestions([...questions, {question: ''}]);

    const removeQuestion = (questionIndex) => {
        const newList = questions?.filter((item, index) => index !== questionIndex);
        setQuestions(newList);
        changeJDInfo({ initialScreeningQAs: newList});
    };

    const insertQuestion = (questionIndex, text) => {
        const updatedQuestions = questions?.map((item, index) => {
            if (index === questionIndex) return { ...item, question: text };
            return item;
        });
        setQuestions(updatedQuestions);
        changeJDInfo({ initialScreeningQAs: updatedQuestions });
    };

    const onBack = () => {
        goToPreviousTab();
    }

    const onNext = () => {
        changeJDInfo({ initialScreeningQAs: questions?.filter((item) => item?.question?.trim()?.length > 0 )});
        goToNextTab();
    }

    return (
        <div className="h-full flex flex-col">
            <div className="bg-white p-5 text-darkGray rounded-md flex flex-col gap-10">
                <div className="flex flex-col gap-8">
                    {questions?.map((item, index) => (
                        <div 
                            key={index?.toString()}
                            className="flex flex-col gap-1">
                            <h1 className="font-semibold text-xs">Question {index + 1}</h1>
                            <div className="flex items-center gap-2">
                                <InputText
                                    value={item.question}
                                    placeholder="Enter question"
                                    onChange={(event) => insertQuestion(index, event.target.value)}
                                    className="border border-[#EAECF0] h-10 text-sm px-2 flex-1"
                                />
                                <div 
                                    onClick={removeQuestion.bind(this, index)}
                                    className="h-8 w-8 shadow-md justify-center flex items-center rounded-full cursor-pointer hover:bg-primary hover:text-white">
                                    <MdClear size={18}/>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div 
                    onClick={addQuestion}
                    className="border-blue border cursor-pointer text-blue px-4 h-10 rounded font-semibold text-xs flex items-center gap-4 w-32">
                    <IoMdAddCircleOutline size={20}/>
                    <span>Add More</span>
                </div>
            </div>
            <div className="flex justify-between">
                <button 
                    onClick={onBack}
                    className='bg-blue h-10 text-sm px-10 rounded-md text-white font-semibold w-32 mt-10'>Back</button>
                <button
                    onClick={onNext} 
                    className='bg-blue h-10 text-sm px-10 rounded-md text-white font-semibold w-32 mt-10'>Next</button>
            </div>
        </div>
    )
}

JDInitialScreeingQAsPage.propTypes = {
    setActiveTabIndex: PropTypes.func
}

export default JDInitialScreeingQAsPage;