import { useEffect, useState } from "react";
import JDDetailsResumes from "./JDDetailsResumes";
import JDService from "../../../services/jd.service";
import LoadingComponent from "../../../components/Common/LoadingComponent";
import JDDetailsHeaderSection from "../../../components/JD/JDDetailsHeaderSection";
import { useParams } from "react-router-dom";

export default function JDDetailsPage(){
    const {id} = useParams();
    const [loading, setLoading] = useState(false);
    const [jobDescription, setJobDescription] = useState(null);
    const [menuVisibility, setMenuVisibility] = useState(null);

    const handleMenuVisibility = (value) => {
        if(value !== menuVisibility)
            setMenuVisibility(value)
    }

    useEffect(() => {
        async function init(){
            try {
                setLoading(true);
                const response = await JDService.getJd(id);
                if(response.status === 200){
                    setJobDescription(response.data.data)
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
        init();
    }, []);

    if(loading){
        return (
            <div className="h-full w-full flex justify-center items-center">
                <LoadingComponent />
            </div>
        )
    }
    
    return (
        <div onClick={handleMenuVisibility.bind(this, false)}
            className="rounded flex flex-col overflow-y-scroll h-full p-5 gap-10" >
            <JDDetailsHeaderSection jd={jobDescription}/>
            <JDDetailsResumes 
                jd={jobDescription}
                menuVisibility={menuVisibility}
                handleMenuVisibility={handleMenuVisibility}
            />
        </div>
    )
}