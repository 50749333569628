import axiosInstance from ".";

function getReport(sessionId, refresh=true){
    return axiosInstance.get(`/reports/${sessionId}?refresh=${refresh}`, {
        responseType: 'blob'
    });
}

function getResumeScreeningReport(resumeId, refresh=true){
    return axiosInstance.get(`/reports/resume-screening/${resumeId}?refresh=${refresh}`, {
        responseType: 'blob'
    });
}


const ReportService = {
    getReport,
    getResumeScreeningReport
}

export default ReportService;