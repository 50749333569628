import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AnimatedDiv } from '../Common/AnimatedDiv';
import AnimatedButton from '../Common/AnimatedButton';

const FeatureCardsSection = () => {
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path)
  }

  return (
    <div className="bg-white p-5 md:p-8">
      <div className="max-w-6xl mx-auto py-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <AnimatedDiv className="bg-[#F6F7F9] rounded-lg shadow-none p-10 flex flex-col justify-between">
            <div className='flex flex-col'>
              <h2 className="text-2xl font-bold mb-4 text-[#161616]">Foster an HR Culture Embracing Future-Forward Hiring</h2>
              <p className="text-brownGray font-light mb-6">
                Cultivate a data-driven HR culture with Athmick's advanced analytics and reporting features, paving the way for innovative hiring techniques.
              </p>
            </div>
            <AnimatedButton
              onClick={navigateTo.bind(this, '/demo')} 
              className="border-[1px] border-primary text-primary text-sm px-6 py-1 rounded-full w-40 hover:bg-blue hover:text-white hover:border-0">
              Book A Demo
            </AnimatedButton>
          </AnimatedDiv>

          <AnimatedDiv className="bg-[#F6F7F9] rounded-lg shadow-none p-10  flex flex-col justify-between">
            <div className='flex flex-col'>
              <h2 className="text-2xl font-bold mb-4 text-[#161616]">Deliver an exceptional candidate experience</h2>
              <p className="text-brownGray font-light mb-6">
                Athmick ensures a seamless journey with flexible, on-demand interviews, allowing candidates to complete them anytime, anywhere at their convenience
              </p>
            </div>
            <AnimatedButton 
              onClick={navigateTo.bind(this, '/try-for-free')} 
              className="bg-primary text-white text-sm px-6 py-2 hover:bg-primary rounded-full w-40">
              Try For Free
            </AnimatedButton>
          </AnimatedDiv>
        </div>
      </div>
    </div>
  );
};

export default FeatureCardsSection;