import './App.css';
import RootRoutes from './routes/RootRoutes';
import { BrowserRouter} from "react-router-dom";
import { MaintenanceProvider } from './contextProviders/MaintenanceContext';


function App() {

  return (
    <BrowserRouter>
      <MaintenanceProvider>
        <RootRoutes />
      </MaintenanceProvider>
    </BrowserRouter>
  );
}

export default App;
