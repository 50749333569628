import { useNavigate } from "react-router-dom";
import { GoArrowUpRight } from "react-icons/go";
import AnimatedText from '../Common/AnimatedText';
import AnimatedButton from "../Common/AnimatedButton";
import { AnimatedImage } from "../Common/AnimatedImage";
import solutionImage from './../../assets/images/solution.svg';
import heroPattern from './../../assets/images/hero-pattern.svg';
import { trackEvent } from "../../utils/googleAnalytics";

export default function HeroSection() {

    const navigate = useNavigate();
    const navigateTo = (path) => navigate(path);
    
    const onBookADemo = () => {
        trackEvent({
            value: 1,
            action: "Click",
            transport: "beacon",
            nonInteraction: false,
            label: "Book a Demo Button",
            category: "Lead Generation",
        })
        navigateTo('/demo')
    }

    return (
        <section className="bg-white">
            <img 
                alt='hero-pattern'
                src={heroPattern}
                className='absolute top-0 left-0'
            />
            <div className='w-full md:max-w-[75%] mx-auto py-10 md:py-16 flex items-center flex-col md:flex-row justify-center gap-10 md:px-0 px-5'>
                <div className="flex-1 flex flex-col gap-5 justify-center items-center md:items-start">
                    <AnimatedText className="font-semibold text-3xl md:text-4xl flex-col md:leading-snug gap-3 text-darkGray tracking-wide leading-relaxed text-center md:text-left">
                        Revolutionize your <span className="text-l_g">Hiring and Future-Proof</span> your Process with Athmick                 
                    </AnimatedText>
                    <p className="text-base mb-8 max-w-2xl text-brownGray font-normal leading-6 tracking-wider text-left">
                        Automate and elevate every critical step of recruitment with Athmick's cutting-edge solutions
                    </p>
                    <AnimatedButton
                        onClick={onBookADemo} 
                        className="bg-black text-white rounded-full md:w-48 hover:bg-blue hover:text-white">
                        <div className='flex gap-2 justify-between items-center md:py-1 text-base'>
                            <span className='font-medium'>Book A Demo</span>
                            <GoArrowUpRight color='white' size={18} />
                        </div>
                    </AnimatedButton>
                </div>
                <div className='flex-1 flex justify-end md:p-0'>
                    <AnimatedImage
                        alt='solution' 
                        src={solutionImage}
                    />
                </div>
            </div>
        </section>
    );
}