import { useState } from 'react';
import { BounceLoader } from 'react-spinners';
import { Rating } from '@smastrom/react-rating';
import { getRating } from '../../utils/common';
import { RESUME_STATUS } from '../../utils/constants';
import ReportService from '../../services/report.service';
import { useToast } from '../../contextProviders/ToastContext';
import CircularProgress from '../../components/Report/CircularProgress';
import CustomReactMarkdown from '../../components/Common/CustomReactMarkdown';
import { useParams } from 'react-router-dom';

export default function ReportResumeScreening({report}){
    const {showErrorMessage} = useToast();
    const {id} = useParams();
    const [loading, setLoading] = useState(false);
    const [hoveredSkill, setHoveredSkill] = useState(null);
    const [hoveredSkillColor, setHoveredSkillColor] = useState(null);
    const resumeScreening = report && report?.resume_screening ? report?.resume_screening[0] : report ? report : {};
    // const skillRelevance = [
    //     ...(resumeScreening['mandatory_skills'] || []),
    //     ...(resumeScreening['optional_skills'] || [])
    // ];
    
    const skillRelevance = resumeScreening?.['mandatory_skills'] || [];

    const extractReport = async () => {
        try {
            setLoading(true);
            const response = await ReportService.getResumeScreeningReport(id);
            setLoading(false);
            if(response.status === 200){
                const blob = new Blob([response.data], { type: "application/pdf" });
                const link = document.createElement("a");

                const fileName = report['candidate'] ? `${report['candidate']['Name']}-${Date.now()}.pdf` : 
                    report['candidate_name'] ? `${report['candidate_name']}-${Date.now()}.pdf` : `${Date.now()}.pdf`

                link.href = URL.createObjectURL(blob);
                link.download = fileName;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (error) {
            setLoading(false);
            showErrorMessage({ summary: 'Failed', detail: 'Failed to extract report'})
        }
    }

    if(report?.status && report?.status['resumeScreening'] === RESUME_STATUS.NA){
        return (
            <div className='flex flex-col gap-10'>
                <h1 className='font-medium text-base'>Resume shortlisting not initiated</h1>
            </div>
        )
    }

    const renderSkills = (skills) => {
        if (!skills || skills.length === 0) return null;
    
        return (
            <div className='flex flex-col gap-3'>
                <ul className="flex gap-4 flex-wrap">
                    {skills.map((item, index) => {
                        const { name, score, weightage } = item;
                        // const percentage = weightage > 0 ? ((score / weightage) * 100).toFixed(2) : 0;
                        const percentage = score <= 0 ? 0 : score?.toFixed(2);
                        const colorClass =
                            percentage >= 80 ? 'bg-lg text-dg' :
                            percentage >= 40 ? 'bg-lo text-do' :
                            'bg-lr text-dr';
        
                        return (
                            <li
                                key={index}
                                onMouseEnter={() => {
                                    setHoveredSkill(item);
                                    setHoveredSkillColor(colorClass);
                                }}
                                onMouseLeave={() => {
                                    setHoveredSkill(null);
                                    setHoveredSkillColor(null);
                                }}
                                className={`flex gap-2 rounded-full cursor-pointer px-2 py-1 font-medium text-xs ${colorClass}`}
                            >
                                <h5>{name}</h5>
                                <span>{percentage}%</span>
                            </li>
                        );
                    })}
                </ul>
                {hoveredSkill && (
                    <div className={`text-sm font-normal w-2/3 px-4 py-2 rounded-full ${hoveredSkillColor} transition-opacity duration-1000 ${
                        hoveredSkill ? 'opacity-100' : 'opacity-0'
                    }`}>
                        <p>{hoveredSkill['summary']}</p>
                    </div>
                )}
            </div>
        );
    };

    const renderExperienceSkills = (skills = []) => {
        if (!skills.length) return null;
    
        return (
            <div className='flex flex-col gap-5 mt-4'>
                <div className='flex'>
                    <div className='flex-1'>
                        <h5 className='font-semibold text-brownGray'>Skill</h5>
                    </div>
                    <div className='flex-1'>   
                        <h5 className='font-semibold text-brownGray'>Required Experience</h5>       
                    </div>
                    <div className='flex-1'>
                        <h5 className='font-semibold text-brownGray'>Candidate Experience</h5>   
                    </div>
                </div>
                <ul className="flex gap-4 flex-col flex-wrap">
                    {skills.map((item, index) => {
                        const { name, required_experience, candidate_experience } = item;
                        const percentage = 
                        required_experience === 0 && candidate_experience >= 0 ? 100 :
                        required_experience ? Math.min((candidate_experience / required_experience) * 100, 100).toFixed(2) : 
                            0;
        
                        const colorClass =
                            percentage >= 80 ? 'bg-lg text-dg' :
                            percentage >= 40 ? 'bg-lo text-do' :
                            'bg-lr text-dr';
        
                        return (
                            <li
                                key={index}
                                className='flex relative rounded-full font-medium text-xs'
                            >
                                <div className='flex-1'>
                                    <h5 className='font-semibold text-primary'>{name}</h5>
                                </div>
                                <div className='flex-1'>   
                                    <span className={`${colorClass} rounded-md px-4 py-1`}>{required_experience} years </span>         
                                </div>
                                <div className='flex-1'>
                                    <span className={`${colorClass} rounded-md px-4 py-1`}>{candidate_experience} years</span>
                                </div>
                            </li>
                        );
                    })}
                </ul>
                {/* {hoveredExperienceSkill && (
                    <div className={`text-sm font-normal gap-4 flex w-1/2 justify-between items-center px-4 py-2 rounded-full ${hoveredExperienceSkillColor} font-medium transition-opacity duration-1000 ${
                        hoveredExperienceSkill ? 'opacity-100' : 'opacity-0'
                    }`}>
                        <span className=''>Required Experience: {hoveredExperienceSkill['required_experience']} years</span>
                        <span>Candidate Experience: {hoveredExperienceSkill['candidate_experience']} years</span>
                    </div>
                )} */}
            </div>
        );
    };   

    return (
        <div className='flex w-full flex-col gap-10'>
            <div className='flex justify-center items-center gap-10'>
                <CircularProgress score={parseFloat(resumeScreening?.score).toFixed(1)}/>
                <div className='flex gap-2 flex-col'>
                    <label className='text-xl font-bold'>Summary</label>
                    <CustomReactMarkdown data={resumeScreening?.summary}/>
                    <button
                        disabled={loading}
                        onClick={extractReport} 
                        className='border h-8 mt-1 w-36 px-6 flex justify-center items-center hover:bg-primary hover:text-white font-medium rounded-md border-primary'>
                        {loading ? <BounceLoader size={26} color="#2B4360" /> : <span className="text-xs">Extract Report</span>}
                    </button>
                </div>
            </div>
            <div className={`flex gap-2 flex-col ${resumeScreening?.matchedReason ? 'visible' : 'hidden'}`}>
                <label className='text-xl font-bold'>Matched Reason</label>
                <p className='text-primary'>{resumeScreening?.matchedReason}</p>
            </div>
            <div className={`flex gap-2 flex-col ${resumeScreening?.irrelevant ? 'visible' : 'hidden'}`}>
                <label className='text-xl font-bold'>Irrelevance</label>
                <p className='text-primary'>{resumeScreening?.irrelevant}</p>
            </div>
            <div className={`flex flex-col gap-10`}>
                <div className={`flex gap-2 flex-col ${resumeScreening?.educationRelevanceSummary && resumeScreening?.candidateExperience <= 2 ? 'visible' : 'hidden'}`}>
                    <div className='flex gap-5'>
                        <h1 className='text-xl font-bold'>Education relevance</h1>
                        <Rating 
                            readOnly
                            color='#B99000' 
                            cancel={false} 
                            style={{width: 120}}
                            value={getRating(resumeScreening?.scoreInfo?.relevant_education)?.value} 
                            className={`${resumeScreening?.scoreInfo?.relevant_education ? 'visible': 'hidden'}`}
                        />
                    </div>
                    <p className='text-primary'>{resumeScreening?.educationRelevanceSummary}</p>
                </div>
                <div className={`flex gap-2 flex-col ${resumeScreening?.skillRelevanceSummary ? 'visible' : 'hidden'}`}>
                    <div className='flex gap-5'>
                        <h1 className='text-xl font-bold'>Skill relevance</h1>
                        <Rating 
                            readOnly
                            color='#B99000' 
                            cancel={false} 
                            style={{width: 120}}
                            value={getRating(resumeScreening?.scoreInfo?.relevant_skill)?.value} 
                            className={`${resumeScreening?.scoreInfo?.relevant_skill ? 'visible': 'hidden'}`}
                        />
                    </div>
                    <p className='text-primary'>{resumeScreening?.skillRelevanceSummary}</p>
                    {renderSkills(skillRelevance)}
                </div>
                <div className={`flex gap-2 flex-col ${resumeScreening?.experienceRelevanceSummary && resumeScreening?.candidateExperience > 1 ? 'visible' : 'hidden'}`}>
                    <div className='flex gap-5'>
                        <h1 className='text-xl font-bold'>Experience Relevance</h1>
                        <Rating 
                            readOnly
                            color='#B99000' 
                            cancel={false} 
                            style={{width: 120}}
                            value={getRating(resumeScreening?.scoreInfo?.relevant_experience).value} 
                            className={`${resumeScreening?.scoreInfo?.relevant_experience ? 'visible': 'hidden'}`}
                        />
                    </div>
                    <p className='text-primary'>{resumeScreening?.experienceRelevanceSummary}</p>
                    {renderExperienceSkills(skillRelevance)}
                </div>
                <div className={`flex gap-2 flex-col ${resumeScreening?.workRelevanceSummary ? 'visible' : 'hidden'}`}>
                    <div className='flex gap-5'>
                        <h1 className='text-xl font-bold'>Work Relevance</h1>
                        <Rating 
                            readOnly
                            color='#B99000' 
                            cancel={false} 
                            style={{width: 120}}
                            value={getRating(resumeScreening?.scoreInfo?.relevant_work).value} 
                            className={`${resumeScreening?.scoreInfo?.relevant_work ? 'visible': 'hidden'}`}
                        />
                    </div>
                    <p className='text-primary'>{resumeScreening?.workRelevanceSummary}</p>
                </div>
                <div className={`flex gap-2 flex-col ${resumeScreening?.industryRelevance && resumeScreening?.candidateExperience > 1 ? 'visible' : 'hidden'}`}>
                    <div className='flex gap-5'>
                        <h1 className='text-xl font-bold'>Industry Relevance</h1>
                        <Rating 
                            readOnly
                            color='#B99000' 
                            cancel={false} 
                            style={{width: 120}}
                            value={getRating(resumeScreening?.scoreInfo?.industry_relevance).value} 
                            className={`${resumeScreening?.scoreInfo?.industry_relevance ? 'visible': 'hidden'}`}
                        />
                    </div>
                    <p className='text-primary'>{resumeScreening?.industryRelevance}</p>
                </div>
                <div className={`flex gap-2 flex-col ${resumeScreening?.companyRelevance && resumeScreening?.candidateExperience > 1 ? 'visible' : 'hidden'}`}>
                    <div className='flex gap-5'>
                        <h1 className='text-xl font-bold'>Company Relevance</h1>
                        <Rating 
                            readOnly
                            color='#B99000' 
                            cancel={false} 
                            style={{width: 120}}
                            value={getRating(resumeScreening?.scoreInfo?.company_relevance).value} 
                            className={`${resumeScreening?.scoreInfo?.company_relevance ? 'visible': 'hidden'}`}
                        />
                    </div>
                    <p className='text-primary'>{resumeScreening?.companyRelevance}</p>
                </div>
                <div className={`flex gap-2 flex-col ${resumeScreening?.missingSkillsSummary ? 'visible' : 'hidden'}`}>
                    <h1 className='text-xl font-bold'>Missing Skills</h1>
                    <p className='text-primary'>{resumeScreening?.missingSkillsSummary}</p>
                </div>
            </div>
        </div>
    )
}