// PagesDataContext.js
import React, { createContext, useContext, useState } from 'react';

const PagesDataContext = createContext();

// Custom hook for easy access to the context
export const usePageData = () => useContext(PagesDataContext);

// PageDataProvider component
export const PageDataProvider = ({ children }) => {
    const [pageData, setPageData] = useState({});  // Stores data per page


    // Function to update data for a specific page
    const updatePageData = (pageId, newData) => {
        setPageData((prevData) => ({
            ...prevData,
            [pageId]: newData,
        }));
    };

    // Function to retrieve data for a specific page
    const getPageData = (pageId) => pageData[pageId] || null;

    return (
        <PagesDataContext.Provider value={{ updatePageData, getPageData }}>
            {children}
        </PagesDataContext.Provider>
    );
};
