import JDPage from "../pages/HR/Job/JDPage";
import { Route, Routes } from "react-router-dom";
import JDEditPage from "../pages/HR/Job/JDEditPage";
import JDCreatePage from "../pages/HR/Job/JDCreatePage";
import JDDetailsPage from "../pages/HR/Job/JDDetailsPage";
import { useAuth } from "../contextProviders/AuthContext";

export default function JDRoutes(){
    const {user} = useAuth();

    if(user && user['role'] === 'admin'){
        return (
            <Routes>
                <Route index path="" element={<JDPage />} />
                <Route path=":id" element={<JDDetailsPage />} />
            </Routes>
        )
    }

    return (
        <Routes>
            <Route index path="" element={<JDPage />} />
            <Route path="create" element={<JDCreatePage />} />
            <Route path=":id/edit" element={<JDEditPage />} />
            <Route path=":id" element={<JDDetailsPage />} />
        </Routes>
    )
}