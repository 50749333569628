import {Knob} from 'primereact/knob';
import ReactPlayer from 'react-player';
import { useRef, useState } from 'react';
import { Rating } from '@smastrom/react-rating';
import { CgTranscript } from "react-icons/cg";
import { RESUME_STATUS } from '../../utils/constants';
import { useAuth } from '../../contextProviders/AuthContext';
import TranscriptComponent from '../../components/Report/TranscriptComponent';
import CustomReactMarkdown from '../../components/Common/CustomReactMarkdown';
import AudioAnalysisReportPopup from '../../components/Report/AudioAnalysisReportPopup';
import { convertUTCToLocal, extractOverallAssessment, getRatingForBpoFlow, getRatingForCommunication, secondsToMinutes } from '../../utils/common';

export default function ReportBpoHrScreening({report}){
    const {user} = useAuth();
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [showTranscript, setShowTranscript] = useState(false);
    const [showAudioAnalyisReport, setShowAudioAnalyisReport] = useState(false);
    const initialScreening = (report && report?.initial_screening) ? report?.initial_screening[0] : report ? report : {};

    const audioAnalysis = initialScreening?.audio_analysis?.filter((item) => item?.speaker === 'SPEAKER_01')?.[0];

    const showAudioAnalysisReportPopup = () => setShowAudioAnalyisReport(true);
    const hideAudioAnalysisReportPopup = () => setShowAudioAnalyisReport(false);

    const getValue = (value) => {
        if(!value) return;
        const v = value ? value?.toString()?.toLowerCase() : null;
        if(v?.includes('low'))
            return <span className='font-semibold text-dr'>{value}</span>
        else if(v?.includes('medium'))
            return <span className='font-semibold text-do'>{value}</span>
        else if(v?.includes('high'))
            return <span className='font-semibold text-dg'>{value}</span>
    }

    const getTone = (value) => {
        if(!value) return;

        const v = value.toLowerCase();
        if(v?.includes('happy') || v?.includes('neutral'))
            return <span className='font-semibold text-dg'>{value}</span>
        else if(v?.includes('anger'))
            return <span className='font-semibold text-dr'>{value}</span>
        else
            return <span className='font-semibold text-do'>{value}</span>
    }

    const getPace = (value) => {
        if(!value) return;

        const v = value.toLowerCase();
        if(v?.includes('fast'))
            return <span className='font-semibold text-dg'>{value}</span>
        else if(v?.includes('slow'))
            return <span className='font-semibold text-dr'>{value}</span>
        else
            return <span className='font-semibold text-do'>{value}</span>
    }

    if(!initialScreening || (typeof initialScreening == 'object' &&  Object.keys(initialScreening).length === 0)){
        return (
            <div className='flex flex-col gap-10'>
                <h1 className='font-medium text-base'>{
                    report?.status['initialScreening'] === RESUME_STATUS.COMPLETED ? 
                        'Report is being prepared, will be available shortly.' : 'Report not initiated'
                    } 
                </h1>
            </div>
        )
    }

    const getScoreTheme = (score) => {
        if(score >= 71) return 'text-dg';
        else if (score >= 49 && score < 71 ) return 'text-do';
        return 'text-dr';
    }

    const handlePlay = () => {
        setIsPlaying(true);
    };
    
    const handlePause = () => {
        setIsPlaying(false);
    };

    const getIntialScreeningScore = (initialScreening) => {
        let percentage = 0;
        if(initialScreening['is_comfortable_with_night_shifts']){
            percentage += 50;
            if(initialScreening?.['planned_leaves']?.toLowerCase()?.includes("no"))
                percentage += 50;
            else percentage += 10;
        }
        else {
            if(initialScreening?.['planned_leaves']?.toLowerCase()?.includes("no"))
                percentage += 10;
        }
        return percentage;
    }

    return (
        <div className='flex flex-col gap-5'>
            {showAudioAnalyisReport && (
                <AudioAnalysisReportPopup 
                    audioAnalysis={audioAnalysis}
                    close={hideAudioAnalysisReportPopup}
                />
            )}
            <div className='flex justify-between items-center'>
                <div className='flex flex-col gap-5'>
                    <div className='flex flex-row justify-between flex-1 gap-5'>
                        <div className='flex justify-end flex-col'>
                            <ReactPlayer
                                ref={videoRef}
                                controls
                                pip={false}
                                playing={isPlaying}
                                onPlay={handlePlay}
                                onPause={handlePause}
                                className="react-player"
                                url={initialScreening?.video_path}
                            />
                        </div>
                        <div className='flex flex-col gap-4 flex-1'>
                            <div className={`flex items-center gap-2 text-primary mt-5 ${initialScreening?.start_time ? 'visible': 'hidden'}`}>
                                <h3 className='font-semibold'>Date of the interview :</h3>
                                <span className=''>{initialScreening?.start_time ? convertUTCToLocal(initialScreening?.start_time) : 'Not captured' }</span>
                            </div>
                            <div className={`flex items-center gap-2 text-primary ${initialScreening?.location ? 'visible': 'hidden'}`}>
                                <h3 className='font-semibold'>Location:</h3>
                                <h4 className=''>{initialScreening?.location}</h4>
                            </div>
                            <div className={`flex items-center gap-2 text-primary ${initialScreening['is_interested'] ? 'visible': 'hidden'}`}>
                                <h3 className='font-semibold'>Is interested in role:</h3>
                                <h4 className=''>{initialScreening['is_interested'] ? 'Yes' : 'No'}</h4>
                            </div>
                            <div className={`flex items-center gap-2 text-primary ${initialScreening?.notice_period ? 'visible': 'hidden'}`}>
                                <h3 className='font-semibold'>Notice Period:</h3>
                                <h4 className=''>{initialScreening?.notice_period}</h4>
                            </div>
                            <div className={`flex items-center gap-2 text-primary ${initialScreening?.is_comfortable_with_night_shifts !== undefined ? 'visible': 'hidden'}`}>
                                <h3 className='font-semibold'>Comfortable with night Shifts:</h3>
                                <h4 className=''>{initialScreening?.is_comfortable_with_night_shifts ? 'Yes': ' No (Candidate rejected due to this)'}</h4>
                            </div>
                            <div className={`flex items-center gap-2 text-primary ${initialScreening?.salary_expectation ? 'visible': 'hidden'}`}>
                                <h3 className='font-semibold'>Salary expectation:</h3>
                                <h4 className=''>{initialScreening?.salary_expectation}</h4>
                            </div>
                            <div className={`flex items-center gap-2 text-primary ${initialScreening?.planned_leaves ? 'visible': 'hidden'}`}>
                                <h3 className='font-semibold'>Planned leaves:</h3>
                                <h4 className=''>{initialScreening?.planned_leaves}</h4>
                            </div>
                            <div className='flex items-center gap-2 text-primary'>
                                <h3 className='font-semibold'>Interview Status:</h3>
                                <span className=''>{initialScreening?.status}</span>
                            </div>
                            <div className='flex items-center gap-2 text-primary'>
                                <h3 className='font-semibold'>Interview Duration:</h3>
                                <span className=''>{initialScreening?.duration ? secondsToMinutes(initialScreening?.duration) : 'NA'}</span>
                            </div>
                            <div className='flex items-center gap-2'>
                                <p className='font-semibold text-primary'>Transcript of the interview</p>
                                <CgTranscript 
                                    size={28}
                                    className="cursor-pointer"
                                    onClick={() => setShowTranscript(!showTranscript)}
                                />
                            </div>
                        </div>
                    </div>
                    {showTranscript && <TranscriptComponent id={initialScreening?.id} hide={() => setShowTranscript(false)}/>}
                </div>
            </div>
            <div className='flex gap-20 items-center'>
                <div className={`flex flex-col gap-4 ${audioAnalysis?.['summary'] ? 'visible': 'hidden'}`}>
                    <h2 className='font-bold text-base'>Overall Conversation Rating</h2>
                    <div className='flex flex-col gap-3'>
                        <Rating 
                            readOnly
                            color='#B99000' 
                            cancel={false} 
                            style={{width: 120}}
                            value={getRatingForBpoFlow(getIntialScreeningScore(initialScreening)).value} 
                        />
                        <h2 className={`text-sm ${getScoreTheme(getIntialScreeningScore(initialScreening))} font-semibold`}>{
                            !initialScreening['is_comfortable_with_night_shifts'] ? 
                                'Candidate Rejected' : 'Overall Conversation Rating'
                            }
                        </h2>
                    </div>
                </div>
                <div className={`flex flex-col gap-3 ${audioAnalysis?.score !== undefined ? 'visible': 'hidden' }`}>
                    <h2 className='font-bold text-base'>Communication Rating</h2>
                    <div className='flex items-center gap-5'>
                        <div className='flex flex-col gap-3'>
                            <Rating 
                                readOnly
                                color='#B99000' 
                                cancel={false} 
                                style={{width: 120}}
                                value={getRatingForCommunication(audioAnalysis?.score, user?.['organization']).value} 
                            />
                            <h2 className={`text-sm font-semibold text-primary`}>
                                {getRatingForCommunication(audioAnalysis?.score, user?.['organization']).title}
                            </h2>
                        </div>
                        <Knob
                            max={100} 
                            size={64}
                            strokeWidth={10}
                            className='text-xs font-bold'
                            valueColor='#3498db' 
                            valueTemplate={'{value}%'}
                            value={parseFloat(audioAnalysis?.score).toFixed(1)} 
                        />
                    </div>
                </div>
            </div>
            <div className={`${initialScreening?.roles_and_responsibilities?.length > 0 ? 'visible': 'hidden'}`}>
                <h4 className='text-xl font-bold'>Roles And Responsibilities</h4>
                <ul className='flex gap-5 flex-wrap my-5'>
                    {initialScreening?.roles_and_responsibilities?.map((item, index) => (
                        <li key={index?.toString()} 
                            className='bg-lightBlue p-3 rounded-lg text-primary'>
                            {item}
                        </li>
                    ))}
                </ul>
            </div>
            <div className={`flex flex-col gap-3 ${initialScreening?.summary ? 'visible': 'hidden'}`}>
                <h4 className='text-xl font-bold'>Summary</h4>
                <CustomReactMarkdown
                    data={initialScreening?.summary}
                />
            </div>
            <div className={`flex flex-col gap-3 pb-5 mt-3 ${initialScreening?.relevance ? 'visible': 'hidden'}`}>
                <h4 className='text-xl font-bold'>Relevance</h4>
                <CustomReactMarkdown
                    data={initialScreening?.relevance}
                />
            </div>
            <div className={`flex flex-col ${audioAnalysis?.['summary'] ? 'visible': 'hidden'} gap-5`}>
                <div className='flex gap-4'>
                    <h2 className='text-xl font-bold'>Communication Skills</h2>
                    <button 
                        onClick={showAudioAnalysisReportPopup} 
                        className='font-medium text-blue'>
                        Show more
                    </button>
                </div>
                <div className='flex flex-col gap-5'>
                    <div className={`flex mt-2 flex-col gap-3 ${extractOverallAssessment(audioAnalysis?.['summary']) ? 'visible': 'hidden' }`}>
                        {extractOverallAssessment(audioAnalysis?.['summary']) && 
                            <CustomReactMarkdown data={extractOverallAssessment(audioAnalysis?.['summary'])}/>
                        }
                    </div>
                    <div className="flex flex-wrap gap-5">
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.tone ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Tone : </h2>
                            {getTone(audioAnalysis?.tone)}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.pace || 'Medium' ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Pace : </h2>
                            {getPace(audioAnalysis?.pace || 'Medium')}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.speech_quality ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Speech Quality : </h2>
                            {getValue(audioAnalysis?.speech_quality)}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.audio_volume ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Audio Volume : </h2>
                            {getValue(audioAnalysis?.audio_volume)}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.audio_quality ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Audio Quality : </h2>
                            {getValue(audioAnalysis?.audio_quality)}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`flex flex-col gap-3 ${initialScreening['candidate_feedback'] && initialScreening['candidate_feedback']?.rating >= 1 ? 'visible': 'hidden'}`}>
                <div className='flex gap-5'>
                    <h1 className='text-xl font-bold'>Candidate Feedback</h1>
                    <Rating 
                        readOnly
                        color='#B99000' 
                        cancel={false} 
                        style={{width: 120}}
                        value={initialScreening['candidate_feedback']?.rating} 
                    />
                </div>
                <p className='text-primary'>{initialScreening['candidate_feedback']?.message}</p>
            </div>
        </div>
    )
}