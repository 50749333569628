const CustomPaginator = (props) => {
    const {
        limit,
        setLimit,
        totalPages,
        totalCount,
        currentPage,
        setCurrentPage,
        // rowsPerPageOptions = [10, 20, 50, 100, 'All'],
        rowsPerPageOptions = [10, 20, 50, 100],
    } = props;

    const handlePageChange = (page) => {
        if (page < 1 || page > totalPages) return;
        setCurrentPage(page);
        setLimit(limit)
    };

    const handleRowsPerPageChange = (e) => {
        const selectedRows = e.target.value === 'All' ? 'All' : parseInt(e.target.value, 10);
        setCurrentPage(1);
        setLimit(selectedRows === 'All' ? totalCount : selectedRows)
    };

    return (
        <div className="custom-paginator flex justify-between items-center py-2">
            <div className="">
                <label htmlFor="rows-per-page" className="mr-2 text-primary">Rows per page:</label>
                <select
                    id="rows-per-page"
                    onChange={handleRowsPerPageChange}
                    value={limit === totalCount ? 'All': limit}
                    className="p-1 border-primary border rounded text-sm bg-white text-primary"
                >
                    {rowsPerPageOptions.map((option) => (
                        <option 
                            key={option}
                            value={option}
                            className="text-sm">
                            {option === 'All' ? 'All' : option}
                        </option>
                    ))}
                </select>
            </div>
            <div className="flex items-center">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={totalPages === 1 || currentPage === 1}
                    className={`p-2 border rounded-md text-sm bg-blue text-white font-semibold mx-1 ${(totalPages === 1 || currentPage === 1) ? 'opacity-30': 'opacity-100'}`}>
                    Prev
                </button>
                <span className="mx-2 text-primary text-sm">
                    Page {currentPage} of {totalPages}
                </span>
                <button
                    disabled={currentPage >= totalPages}
                    onClick={() => handlePageChange(currentPage + 1)}
                    className={`p-2 border rounded mx-1 bg-blue text-white font-semibold text-sm ${currentPage >= totalPages ? 'opacity-30': 'opacity-100'}`}>
                    Next
                </button>
            </div>
        </div>
    );
};

export default CustomPaginator;