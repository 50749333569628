import { FaCode } from "react-icons/fa";
import React, { useCallback, useState } from "react";
import InterviewExitPopup from "./InterviewExitPopup";
import { RESUME_STAGES } from "../../utils/constants";
import { useSessionContext } from "../../contextProviders/SessionContext";
import { useMediaContext } from "../../contextProviders/InterviewMediaContext";
import ConsoleHelper from "../../utils/consoleHelper";

function InterviewRoomFooter({stopRecording, onExit, showCodeEditor}){
    ConsoleHelper.log('InterviewRoomFooter')

    const { sessionInfo } = useSessionContext();
    const { audioStream } = useMediaContext();
    const [showExitPopup, setShowExitPopup] = useState(false);
    const { transcripts } = useSessionContext();

    const isMicOn = useCallback((state = true) => {
        const audioTracks = audioStream?.getAudioTracks();
        audioTracks?.forEach(track => { track.enabled = state });
    }, [audioStream]);

    const handleExitClick = useCallback(() => setShowExitPopup(true), []);

    const handleCodeEditorClick = useCallback(() => {
        showCodeEditor(true);
        isMicOn(false);
    }, [showCodeEditor, isMicOn]);

    function getInterviewType(){
        let interviewType = 'Initial Screening';
        if(sessionInfo.interview_type === RESUME_STAGES.TECHNICAL_SCREENING)
            interviewType = 'Technical Assessment';
        else if (sessionInfo.interview_type === RESUME_STAGES.OPS_SCREENING)
            interviewType = 'OPS Interview';
        else if (sessionInfo.interview_type === RESUME_STAGES.EXIT_INTERVIEW)
            interviewType = 'Exit Interview';
        else if (sessionInfo.interview_type === RESUME_STAGES.VIDEO_PROFILING)
            interviewType = 'Video Profiling';

        return interviewType;
    }

    return (
        <div className="z-20 flex justify-center text-white text-xs 2xl:text-sm font-medium items-center bg-[#1F3044]">
            <div className="md:w-[90%] flex justify-center items-center h-16 2xl:h-20 px-5">
                <div className="flex gap-5 w-4/5 flex-1 items-center">
                    <div className="flex gap-2 items-center">
                        <span>{getInterviewType()}</span>
                        <span>|</span>
                        <p>JOB TITLE : <span className="text-blue font-semibold">{sessionInfo?.job_title}</span></p>
                    </div>
                    {/* <span className={`${isConnected ? 'text-dg' : 'text-do'} text-base`}>{isConnected ? 'Connected' : 'Connecting . . .'}</span> */}
                </div>
                {/* <div className="h-9 w-9 2xl:h-11 2xl:w-11 flex justify-center items-center bg-white rounded-full text-primary cursor-pointer">
                    <MdMicNone
                        size={window.screen.width > 1500 ? 24 : 18} 
                        onClick={toggleMute}
                        className={`${isMicMuted ? 'hidden': 'visible'}`}
                    />
                    <MdMicOff
                        size={window.screen.width > 1500 ? 24 : 18}
                        onClick={toggleMute}  
                        className={`${!isMicMuted ? 'hidden': 'visible'}`}
                    />
                </div> */}
                <div className="flex-1 justify-end flex gap-10 items-center">
                    <button
                        onClick={handleExitClick} 
                        className="bg-dr 2xl:h-10 2xl:px-10 px-7 h-8 rounded">
                        Exit Interview
                    </button>
                </div>
                <div 
                    onClick={handleCodeEditorClick}
                    className={`flex gap-3 ml-10 items-center cursor-pointer ${sessionInfo.interview_type === RESUME_STAGES.TECHNICAL_SCREENING && transcripts[transcripts?.length - 1]?.is_coding_question ? 'visible': 'hidden'}`}>
                    <h1 className="text-base font-semibold">Code Editor</h1>
                    <FaCode size={20} />
                </div>
                {/* <div className="flex gap-10 w-1/5 flex-1 justify-end">
                    <IoMdHelpCircleOutline 
                        size={24}
                        className="cursor-pointer"
                    />
                    <HiOutlineUsers 
                        size={24}
                        className="cursor-pointer"
                    />
                    <MdOutlineMessage 
                        size={24}
                        className="cursor-pointer"
                        onClick={toggleTranscriptView}
                    />
                </div> */}
                <InterviewExitPopup
                    onExit={onExit}
                    visible={showExitPopup} 
                    stopRecording={stopRecording}
                    cancel={() => setShowExitPopup(false)}
                />
            </div>
        </div>
    )
}

export default React.memo(InterviewRoomFooter)