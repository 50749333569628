import React from 'react';
import { useNavigate } from 'react-router-dom';
import { GoArrowUpRight } from "react-icons/go";
import AnimatedText from '../Common/AnimatedText';
import awsIcon from './../../assets/icons/aws.png';
import { AnimatedDiv } from '../Common/AnimatedDiv';
import AnimatedButton from '../Common/AnimatedButton';
import image1 from './../../assets/images/hero-1.svg';
import image2 from './../../assets/images/hero-2.svg';
import image3 from './../../assets/images/hero-3.svg';
import { AnimatedImage } from '../Common/AnimatedImage';
import fullStackIcon from './../../assets/icons/full-stack.png';
import pattern1 from './../../assets/images/hero-pattern-1.svg';
import pattern2 from './../../assets/images/hero-pattern-2.svg';
import pattern3 from './../../assets/images/hero-pattern-3.svg';
import pattern4 from './../../assets/images/hero-pattern-4.svg';
import heroPattern from './../../assets/images/hero-pattern.svg';
import blockchainIcon from './../../assets/icons/blockchain.png';
import { trackEvent } from '../../utils/googleAnalytics';

const HeroSection = () => {

  const navigate = useNavigate();
  const navigateTo = (path) => navigate(path);
  
  const onBookADemo = () => {
    trackEvent({
      value: 1,
      action: "Click",
      transport: "beacon",
      nonInteraction: false,
      label: "Book a Demo Button",
      category: "Lead Generation",
    })
    navigateTo('/demo')
  }

  return (
    <div className="bg-white">
      <img 
          alt='hero-pattern'
          src={heroPattern}
          className='absolute top-0 left-0'
      />
      <div className="w-full md:max-w-[80%] mx-auto py-10 md:py-14 flex md:gap-28 items-center flex-col md:flex-row justify-center md:px-0 px-5">
        <div className='flex-1 flex flex-col gap-5 justify-center items-center'>
          <AnimatedDiv className="font-semibold text-3xl md:text-4xl flex-col gap-2 text-darkGray md:leading-snug tracking-wide text-center ">
            <p>Enable HR and hiring managers to achieve</p>
            <p className='text-l_g text-5xl md:text-6xl font-bold'>10x</p>
            <p className='text-3xl md:text-4xl text-l_g'>recruitment effectiveness</p>
          </AnimatedDiv>
          <AnimatedButton
            onClick={onBookADemo} 
            className="bg-black text-white rounded-full md:w-48 mt-7 md:mt-16 hover:bg-blue hover:text-white">
            <div className='flex gap-2 justify-between items-center py-1 text-base'>
              <span className='font-medium'>Book A Demo</span>
              <GoArrowUpRight color='white' size={18} />
            </div>
          </AnimatedButton>
          <p className="text-brownGray mb-10 flex items-center font-light text-sm">
            <strong className='text-primary font-normal text-base leading-tight tracking-wide px-2'>#100+</strong>  interviews taken on <div className='flex ml-4'>
              <div className='h-8 w-8 md:h-10 md:w-10 bg-white rounded-full justify-center items-center flex shadow'>
                <img
                  alt='icon' 
                  src={fullStackIcon}
                  className='h-5 w-5 md:h-6 md:w-6'
                />
              </div>
              <div className='h-8 w-8 md:h-10 md:w-10 bg-white rounded-full justify-center relative -left-2 items-center flex shadow'>
                <img
                  alt='icon'
                  src={awsIcon} 
                  className='h-5 w-5 md:h-6 md:w-6'
                />
              </div>
              <div className='h-8 w-8 md:h-10 md:w-10 bg-white rounded-full justify-center relative -left-4 items-center flex shadow'>
                <img 
                  alt='icon'
                  src={blockchainIcon}
                  className='h-5 w-5 md:h-6 md:w-6' 
                />
              </div>
            </div>
          </p>
        </div>
     
        <div className="flex-1 flex justify-end p-10 md:p-0 relative">
            <div className='relative'>
              <img src={pattern1} alt="Overlay 1" className="absolute -left-10 z-10 bottom-0" />
              <div className='absolute top-0 z-10 right-0'>
                <img src={pattern4} alt="Overlay 3" className="" />
              </div>
              <AnimatedDiv className='absolute z-40 top-48 right-6 animate-float'>
                <AnimatedImage
                  alt="image"
                  src={image2}
                  className="w-40 md:w-fit"
                />
                <img 
                  src={pattern2} 
                  alt="Overlay 2" 
                  className="absolute z-10 -top-6 left-40 h-14" 
                />
              </AnimatedDiv>
              <AnimatedDiv className='z-30 relative'>
                <AnimatedImage
                  alt="image"
                  src={image1} 
                  className='absolute z-30 w-40 md:w-fit top-20 -left-[60%] md:-left-[70%] animate-float'
                />
                <AnimatedImage
                  alt="image"
                  src={image3} 
                  className='absolute z-30 w-40 md:w-fit -bottom-20 -left-[60%] animate-float'
                />
                <img src={pattern3} alt="Background" className='z-10 w-[90%]'/>
              </AnimatedDiv>
            </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;