import ReactPlayer from 'react-player';
import { useRef, useState } from 'react';
import { CgTranscript } from "react-icons/cg";
import { Rating } from '@smastrom/react-rating';
import { BounceLoader } from 'react-spinners';
import { RESUME_STATUS } from '../../utils/constants';
import ReportService from '../../services/report.service';
import { useToast } from '../../contextProviders/ToastContext';
import CircularProgress from '../../components/Report/CircularProgress';
import CustomReactMarkdown from '../../components/Common/CustomReactMarkdown';
import TranscriptComponent from '../../components/Report/TranscriptComponent';
import { capitalizeFirstLetterOfEachWord, convertUTCToLocal, getRating, secondsToMinutes } from '../../utils/common';
import { useAuth } from '../../contextProviders/AuthContext';
import AudioAnalysisReportPopup from '../../components/Report/AudioAnalysisReportPopup';

export default function ReportVideoProfiling({report}){
    const videoRef = useRef(null);
    const {user} = useAuth();
    const {showErrorMessage} = useToast();
    const [loading, setLoading] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [hoveredSkill, setHoveredSkill] = useState(null);
    const [showTranscript, setShowTranscript] = useState(false);
    const resumeVideoProfiling = (report && report?.video_profiling) ? report?.video_profiling[0] : report ? report : {};

    const [hoveredSkillColor, setHoveredSkillColor] = useState(null);

    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);
    const [showAudioAnalyisReport, setShowAudioAnalyisReport] = useState(false);
    const audioAnalysis = resumeVideoProfiling?.audio_analysis?.filter((item) => item?.speaker === 'SPEAKER_01')[0];


    const mandatorySkills = resumeVideoProfiling && resumeVideoProfiling?.['mandatory_skills'] ? resumeVideoProfiling?.['mandatory_skills'] : [];
    const optionalSkills = resumeVideoProfiling && resumeVideoProfiling?.['optional_skills'] ? resumeVideoProfiling?.['optional_skills'] : [];
    const skillRelevance = [...mandatorySkills , ...optionalSkills ];

    const showAudioAnalysisReportPopup = () => setShowAudioAnalyisReport(true);
    const hideAudioAnalysisReportPopup = () => setShowAudioAnalyisReport(false);

    if(!resumeVideoProfiling || Object.keys(resumeVideoProfiling).length === 0){
        return (
            <div className='flex flex-col gap-10'>
                <h1 className='font-medium text-base'>{
                    report?.status['videoProfiling'] === RESUME_STATUS.COMPLETED ? 'Report is being prepared, will be available shortly.' : 'Report not initiated'} 
                </h1>
            </div>
        )
    }

    const getValue = (value) => {
        if(!value) return;
        const v = value ? value?.toString()?.toLowerCase() : null;
        if(v?.includes('low'))
            return <span className='font-semibold text-dr'>{value}</span>
        else if(v?.includes('medium'))
            return <span className='font-semibold text-do'>{value}</span>
        else if(v?.includes('high'))
            return <span className='font-semibold text-dg'>{value}</span>
    }

    const getTone = (value) => {
        if(!value) return;

        const v = value ? value?.toString()?.toLowerCase() : null;
        if(v?.includes('happy') || v?.includes('neutral'))
            return <span className='font-semibold text-dg'>{value}</span>
        else if(v?.includes('anger'))
            return <span className='font-semibold text-dr'>{value}</span>
        else
            return <span className='font-semibold text-do'>{value}</span>
    }

    const getPace = (value) => {
        if(!value) return;

        const v = value ? value?.toString()?.toLowerCase() : null;
        if(v?.includes('fast'))
            return <span className='font-semibold text-dg'>{value}</span>
        else if(v?.includes('slow'))
            return <span className='font-semibold text-dr'>{value}</span>
        else
            return <span className='font-semibold text-do'>{value}</span>
    }

    const extractReport = async () => {
        try {
            setLoading(true);
            const response = await ReportService.getReport(report['id'] || resumeVideoProfiling['id']);
            setLoading(false);
            if(response.status === 200){
                const blob = new Blob([response.data], { type: "application/pdf" });
                const link = document.createElement("a");

                const fileName = report['candidate'] ? `${report['candidate']['Name']}-${Date.now()}.pdf` : 
                    report['candidate_name'] ? `${report['candidate_name']}-${Date.now()}.pdf` : `${Date.now()}.pdf`

                link.href = URL.createObjectURL(blob);
                link.download = fileName;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (error) {
            showErrorMessage({ summary: 'Failed', detail: 'Failed to extract report'})
            setLoading(false);
        }
    }

    const renderSkills = (skills) => {
        if (!skills || skills.length === 0) return null;
    
        return (
            <div className='flex flex-col gap-3'>
                <ul className="flex gap-4 flex-wrap">
                    {skills.map((item, index) => {
                        const { name, score, weightage } = item;
                        const percentage = weightage > 0 ? ((score / weightage) * 100).toFixed(2) : 0;
                        const colorClass =
                            percentage >= 80 ? 'bg-lg text-dg' :
                            percentage >= 40 ? 'bg-lo text-do' :
                            'bg-lr text-dr';
        
                        return (
                            <li
                                key={index}
                                onMouseEnter={() => {
                                    setHoveredSkill(item);
                                    setHoveredSkillColor(colorClass);
                                }}
                                onMouseLeave={() => {
                                    setHoveredSkill(null);
                                    setHoveredSkillColor(null);
                                }}
                                className={`flex gap-2 rounded-full cursor-pointer px-2 py-1 font-medium text-xs ${colorClass}`}
                            >
                                <h5>{name}</h5>
                                <span>{percentage}%</span>
                            </li>
                        );
                    })}
                </ul>
                {hoveredSkill && (
                    <div className={`text-sm font-normal w-2/3 px-4 py-2 rounded-full ${hoveredSkillColor} transition-opacity duration-1000 ${
                        hoveredSkill ? 'opacity-100' : 'opacity-0'
                    }`}>
                        <p>{hoveredSkill['summary']}</p>
                    </div>
                )}
            </div>
        );
    };

    const renderExperienceSkills = (skills = []) => {
        if (!skills.length) return null;
    
        return (
            <div className='flex flex-col gap-5 mt-4'>
                <div className='flex'>
                    <div className='flex-1'>
                        <h5 className='font-semibold text-brownGray'>Skill</h5>
                    </div>
                    <div className='flex-1'>   
                        <h5 className='font-semibold text-brownGray'>Required Experience</h5>       
                    </div>
                    <div className='flex-1'>
                        <h5 className='font-semibold text-brownGray'>Candidate Experience</h5>   
                    </div>
                </div>
                <ul className="flex gap-4 flex-col flex-wrap">
                    {skills.map((item, index) => {
                        const { name, required_experience, candidate_experience } = item;
                        const percentage = 
                        required_experience === 0 && candidate_experience >= 0 ? 100 :
                        required_experience ? Math.min((candidate_experience / required_experience) * 100, 100).toFixed(2) : 
                            0;
        
                        const colorClass =
                            percentage >= 80 ? 'bg-lg text-dg' :
                            percentage >= 40 ? 'bg-lo text-do' :
                            'bg-lr text-dr';
        
                        return (
                            <li
                                key={index}
                                className='flex relative rounded-full font-medium text-xs'
                            >
                                <div className='flex-1'>
                                    <h5 className='font-semibold text-primary'>{name}</h5>
                                </div>
                                <div className='flex-1'>   
                                    <span className={`${colorClass} rounded-md px-4 py-1`}>{required_experience} years </span>         
                                </div>
                                <div className='flex-1'>
                                    <span className={`${colorClass} rounded-md px-4 py-1`}>{candidate_experience} years</span>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    };  

    return (
        <div className='flex w-full flex-col pt-5 gap-10'>
            {showAudioAnalyisReport && (
                <AudioAnalysisReportPopup 
                    audioAnalysis={audioAnalysis}
                    close={hideAudioAnalysisReportPopup}
                />
            )}
            <div className='flex flex-col gap-5'>
                <div className='flex flex-row justify-between flex-1 gap-5'>
                    <div className='flex justify-end flex-col'>
                        <ReactPlayer
                            ref={videoRef}
                            controls
                            pip={false}
                            playing={isPlaying}
                            onPlay={handlePlay}
                            onPause={handlePause}
                            className="react-player"
                            url={resumeVideoProfiling?.video_path}
                        />
                    </div>
                    <div className='flex flex-col gap-4 flex-1 items-start'>
                        <div className='flex justify-center items-center gap-10 pl-20'>
                            <CircularProgress score={parseFloat(resumeVideoProfiling?.score || resumeVideoProfiling?.total_score).toFixed(1)}/>
                        </div>
                        <div className={`flex items-center gap-2 text-primary mt-5 ${resumeVideoProfiling?.start_time ? 'visible': 'hidden'}`}>
                            <h3 className='font-semibold'>Date of the interview :</h3>
                            <span className=''>{resumeVideoProfiling?.start_time ? convertUTCToLocal(resumeVideoProfiling?.start_time) : 'Not captured' }</span>
                        </div>
                        <div className={`flex items-center gap-2 text-primary ${resumeVideoProfiling['is_interested'] ? 'visible': 'hidden'}`}>
                            <h3 className='font-semibold'>Is interested in role:</h3>
                            <h4 className=''>{resumeVideoProfiling['is_interested'] ? 'Yes' : 'No'}</h4>
                        </div>
                        <div className='flex items-center gap-2 text-primary'>
                            <h3 className='font-semibold'>Interview Duration:</h3>
                            <span className=''>{resumeVideoProfiling?.duration ? secondsToMinutes(resumeVideoProfiling?.duration) : 'NA'}</span>
                        </div>
                        <div className='flex items-center gap-2'>
                            <p className='font-semibold text-primary'>Transcript of the interview</p>
                            <CgTranscript 
                                size={28}
                                className="cursor-pointer"
                                onClick={() => setShowTranscript(!showTranscript)}
                            />
                        </div>
                        <button
                            disabled={loading}
                            onClick={extractReport} 
                            className='border h-9 w-36 px-6 flex justify-center items-center hover:bg-primary hover:text-white font-medium rounded-md border-primary'>
                            {loading ? <BounceLoader size={26} color="#2B4360" /> : <span className="text-xs">Extract Report</span>}
                        </button>
                    </div>
                </div>
                {showTranscript && <TranscriptComponent id={resumeVideoProfiling?.id || resumeVideoProfiling?._id} hide={() => setShowTranscript(false)}/>}
            </div>
            <div className={`flex flex-col gap-3 ${resumeVideoProfiling?.summary ? 'visible': 'hidden'}`}>
                <h4 className='text-xl font-bold'>Overall Summary</h4>
                <CustomReactMarkdown
                    data={resumeVideoProfiling?.summary}
                />
            </div>
            <div className={`flex flex-col gap-3 ${resumeVideoProfiling?.conversation_summary ? 'visible': 'hidden'}`}>
                <h4 className='text-xl font-bold'>Conversation Summary</h4>
                <CustomReactMarkdown
                    data={resumeVideoProfiling?.conversation_summary}
                />
            </div>	
            <div className={`flex flex-col gap-3 ${resumeVideoProfiling?.relevance ? 'visible': 'hidden'}`}>
                <h4 className='text-xl font-bold'>Overall Relevance</h4>
                <CustomReactMarkdown data={resumeVideoProfiling?.relevance} />
            </div>
            <div className={`flex gap-2 flex-col ${resumeVideoProfiling?.skill_relevance ? 'visible' : 'hidden'}`}>
                    <div className='flex gap-5'>
                        <h1 className='text-xl font-bold'>Skill relevance</h1>
                        <Rating 
                            readOnly
                            color='#B99000' 
                            cancel={false} 
                            style={{width: 120}}
                            value={getRating(resumeVideoProfiling?.skill_relevance?.score || 0)?.value} 
                        />
                    </div>
                    <p className='text-primary'>{resumeVideoProfiling?.skill_relevance?.summary}</p>
                    {renderSkills(skillRelevance)}
            </div>
            <div className={`flex gap-2 flex-col ${resumeVideoProfiling?.experience_relevance ? 'visible' : 'hidden'}`}>
                <div className='flex gap-5'>
                    <h1 className='text-xl font-bold'>Experience Relevance</h1>
                    <Rating 
                        readOnly
                        color='#B99000' 
                        cancel={false} 
                        style={{width: 120}}
                        value={getRating(resumeVideoProfiling?.experience_relevance?.score || 0).value} 
                    />
                </div>
                <p className='text-primary'>{resumeVideoProfiling?.experience_relevance?.summary}</p>
                {renderExperienceSkills(skillRelevance)}
            </div>
            <div className={`flex gap-2 flex-col ${resumeVideoProfiling?.work_relevance ? 'visible' : 'hidden'}`}>
                <div className='flex gap-5'>
                    <h1 className='text-xl font-bold'>Work Relevance</h1>
                    <Rating 
                        readOnly
                        color='#B99000' 
                        cancel={false} 
                        style={{width: 120}}
                        value={getRating(resumeVideoProfiling?.work_relevance?.score || 0).value} 
                    />
                </div>
                <p className='text-primary'>{resumeVideoProfiling?.work_relevance?.summary}</p>
            </div>
            {/* <div className={`flex gap-2 flex-col ${resumeVideoProfiling?.industry_relevance ? 'visible' : 'hidden'}`}>
                <div className='flex gap-5'>
                    <h1 className='text-xl font-bold'>Industry Relevance</h1>
                    <Rating 
                        readOnly
                        color='#B99000' 
                        cancel={false} 
                        style={{width: 120}}
                        value={getRating(resumeVideoProfiling?.industry_relevance?.score || 0).value} 
                    />
                </div>
                <p className='text-primary'>{resumeVideoProfiling?.industry_relevance?.summary}</p>
            </div>
            <div className={`flex gap-2 flex-col ${resumeVideoProfiling?.company_relevance ? 'visible' : 'hidden'}`}>
                <div className='flex gap-5'>
                    <h1 className='text-xl font-bold'>Company Relevance</h1>
                    <Rating 
                        readOnly
                        color='#B99000' 
                        cancel={false} 
                        style={{width: 120}}
                        value={getRating(resumeVideoProfiling?.company_relevance?.score || 0).value} 
                    />
                </div>
                <p className='text-primary'>{resumeVideoProfiling?.company_relevance?.summary}</p>
            </div> */}
            <div className={`flex flex-col gap-5 ${resumeVideoProfiling?.roles_and_responsibilities?.length > 0 ? 'visible': 'hidden'}`}>
                <h4 className='text-xl font-bold'>Roles And Responsibilities</h4>
                <ul className='flex gap-5 flex-wrap'>
                    {resumeVideoProfiling?.roles_and_responsibilities?.map((item, index) => (
                        <li key={index?.toString()} 
                            className='bg-lightBlue p-3 rounded-lg text-primary'>
                            {item}
                        </li>
                    ))}
                </ul>
            </div>
            <div className={`flex flex-col ${resumeVideoProfiling && resumeVideoProfiling?.['strengths'] && resumeVideoProfiling?.['strengths']?.length > 0 ? 'visible': 'hidden'}`}>
                <h2 className='text-xl font-bold'>Strengths</h2>
                <ul className='flex gap-10 flex-wrap mt-3'>
                    {resumeVideoProfiling?.['strengths'] && resumeVideoProfiling?.['strengths'].map((item, index) => {
                        return (
                            <li 
                                className='w-[45%]'
                                key={index?.toString()}>
                                <div className='flex justify-between gap-2 flex-col'>
                                    <h5 className='text-sm font-bold'>{capitalizeFirstLetterOfEachWord(item['strength'])}</h5>
                                    <p className='text-sm font-normal'>{item['description']}</p>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className={`flex flex-col ${resumeVideoProfiling?.['areas_of_improvement'] && resumeVideoProfiling?.['areas_of_improvement']?.length > 0 ? 'visible': 'hidden'}`}>
                <h2 className='text-xl font-bold'>Areas of improvement</h2>
                <ul className='flex gap-10 flex-wrap mt-3'>
                    {resumeVideoProfiling?.['areas_of_improvement'] && resumeVideoProfiling?.['areas_of_improvement'].map((item, index) => {
                        return (
                            <li 
                                className='w-[45%]'
                                key={index?.toString()}>
                                <div className='flex justify-between gap-2 flex-col'>
                                    <h5 className='text-sm font-bold'> {capitalizeFirstLetterOfEachWord(item['area'])}</h5>
                                    <p className='text-sm font-normal'>{item['description']}</p>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className={`flex flex-col ${resumeVideoProfiling?.['responses'] && Object.keys(resumeVideoProfiling['responses'])?.length > 0 ? 'visible': 'hidden'}`}>
                <h2 className='text-xl font-bold'>Responses</h2>
                <ul className='flex gap-10 flex-wrap mt-3'>
                    {resumeVideoProfiling?.['responses'] && Object.keys(resumeVideoProfiling['responses']).map((item, index) => {
                            return (
                                <li 
                                    className='w-[45%]'
                                    key={index?.toString()}>
                                    <div className='flex justify-between gap-2 flex-col'>
                                        <h5 className='text-sm font-bold'> {capitalizeFirstLetterOfEachWord(item)?.replaceAll('_', ' ')}</h5>
                                        <p className='text-sm font-normal'>{resumeVideoProfiling['responses'][item]['response']}</p>
                                        <div className='mt-2 flex flex-col gap-2'>
                                            <h4 className='text-sm font-bold'>Analysis</h4>
                                            <p className='text-xs font-semibold text-dg'>{resumeVideoProfiling['responses'][item]['analysis']['positive']}</p>
                                            <p className='text-xs font-semibold text-dr'>{resumeVideoProfiling['responses'][item]['analysis']['negative']}</p>
                                        </div>
                                    </div>
                                </li>
                            )
                    })}
                </ul>
            </div>
            <div className={`flex flex-col ${audioAnalysis?.['summary'] ? 'visible': 'hidden'} gap-2`}>
                <div className='flex gap-4'>
                    <h2 className='text-xl font-bold'>Communication Skills</h2>
                    <button 
                        onClick={showAudioAnalysisReportPopup} 
                        className='font-medium text-blue'>
                        Show more
                    </button>
                </div>
                <div className='flex flex-col gap-5'>
                    <div className='flex gap-5 items-center'>
                        <CircularProgress
                            size={60}
                            strokeWidth={7} 
                            score={parseFloat(audioAnalysis?.score).toFixed(1)}
                        />
                        <div className={`flex items-center flex-col gap-2 ${audioAnalysis?.score !== undefined ? 'visible': 'hidden' }`}>
                            <Rating 
                                readOnly
                                color='#B99000' 
                                cancel={false} 
                                style={{width: 120}}
                                value={getRating(audioAnalysis?.score, user?.['organization']).value} 
                            />
                            <span className='font-semibold text-xs'>{getRating(audioAnalysis?.score, user?.['organization']).value} out of 5 stars</span>
                        </div>
                    </div>
                    <div className="flex flex-wrap gap-5">
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.tone ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Tone : </h2>
                            {getTone(audioAnalysis?.tone)}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.pace || 'Medium' ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Pace : </h2>
                            {getPace(audioAnalysis?.pace || 'Medium')}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.speech_quality ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Speech Quality : </h2>
                            {getValue(audioAnalysis?.speech_quality)}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.audio_volume ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Audio Volume : </h2>
                            {getValue(audioAnalysis?.audio_volume)}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.audio_quality ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Audio Quality : </h2>
                            {getValue(audioAnalysis?.audio_quality)}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`flex flex-col gap-3 ${resumeVideoProfiling['candidate_feedback'] && resumeVideoProfiling['candidate_feedback']?.rating >= 1 ? 'visible': 'hidden'}`}>
                <div className='flex gap-5'>
                    <h1 className='text-xl font-bold'>Candidate Feedback</h1>
                    <Rating 
                        readOnly
                        color='#B99000' 
                        cancel={false} 
                        style={{width: 120}}
                        value={resumeVideoProfiling['candidate_feedback']?.rating} 
                    />
                </div>
                <p className='text-primary'>{resumeVideoProfiling['candidate_feedback']?.message}</p>
            </div>
        </div>
    )
}