import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { FaEquals } from "react-icons/fa";
import { useEffect, useState } from 'react';
import { Skeleton } from 'primereact/skeleton';
import { RESUME_STAGES } from '../../../utils/constants';
import CommonService from '../../../services/common.service';
import growthIcon from './../../../assets/icons/growth.svg';
import declineIcon from './../../../assets/icons/decline.svg';
import { useConfig } from '../../../contextProviders/ConfigContext';
import applicantIcon from './../../../assets/icons/applicant.svg';
import { usePageData } from '../../../contextProviders/PagesDataContext';
import exitInterviewIcon from './../../../assets/icons/exit-interview.svg';
import initialInterviewIcon from './../../../assets/icons/initial-interview.svg';
import technicalInterviewIcon from './../../../assets/icons/technical-interview.svg';
import { useAuth } from '../../../contextProviders/AuthContext';

function HRDashboardAnalyticsSection(props){
    const config = useConfig();
    const {user} = useAuth();
    const navigate = useNavigate();
    const {updatePageData } = usePageData();
    const {selectedYear, handleSelectedJd} = props;
    const [loading, setLoading] = useState(false);
    const [analytics, setAnalytics] = useState(null);

    const navigateTo = (path, stage) => { 
        updatePageData('jd-page', {});
        updatePageData('jd-resumes', {});
        updatePageData('sessions-page', props);
        navigate(path, {state: {interviewType: stage}});
    }

    useEffect(() => {
        async function init(){
            setLoading(true);
            try {
                const response = await CommonService.getAnalytics(selectedYear);
                if(response.status === 200)
                    setAnalytics(response.data);
                setLoading(false)
            } catch (error) {
                setLoading(false);
            }
        }
        init();
    }, [selectedYear]);

    if(loading) {
        return (
            <div className="flex gap-5">
                {[1, 2, 3, 4].map((item) => (
                    <div key={item} className="bg-white py-5 px-4 w-1/3 gap-6 flex flex-col rounded-md shadow hover:scale-105 cursor-pointer transition-transform duration-200">
                        <div className="flex items-center justify-between">
                            <div className="flex flex-col gap-1">
                                <Skeleton width="100px" height="16px" />
                                <Skeleton width="80px" height="36px" className="mt-1" />
                            </div>
                            <Skeleton shape="circle" size="40px" />
                        </div>
                        <div className='flex items-center gap-2 mt-4'>
                            <Skeleton shape="circle" size="32px" />
                            <Skeleton width="100px" height="16px" />
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div className="flex flex-wrap gap-5" onClick={handleSelectedJd.bind(this, null)}>
            <div 
                onClick={() => navigate('/dashboard/sessions', {state: {interviewType: RESUME_STAGES.RESUME_SCREENING}})}
                className="bg-white py-5 px-4 w-1/5 gap-6 flex flex-col rounded-md shadow hover:scale-105 cursor-pointer transition-transform duration-200">
                <div className="flex items-center justify-between">
                    <div className="flex flex-col gap-1">
                        <span className='text-[#999999] font-normal text-sm'>Total applicants</span>
                        <h1 className='text-[#161616] text-3xl font-bold'>{analytics?.totalApplicants}</h1>
                    </div>
                    <img alt='icon' src={applicantIcon} className='h-10 w-10'/>
                </div>
                <div className='flex items-center gap-2'>
                    <img
                        alt='icon'  
                        className={`h-8 w-8 ${analytics?.totalApplicantsGrowthRate !== 0  ? 'visible': 'hidden'}`}
                        src={analytics?.totalApplicantsGrowthRate < 0 ? declineIcon : growthIcon}
                    />
                    <div className={`h-8 w-8 rounded-full bg-lightBlue flex justify-center items-center 
                        ${analytics?.totalApplicantsGrowthRate === 0 ? 'visible': 'hidden'}`}>
                        <FaEquals size={15} color='#3C99CA'/>
                    </div>
                    <p className='text-[#999999] text-sm font-normal'>
                        <span className={analytics?.totalApplicantsGrowthRate < 0 ? 'text-[#FF3B30]': 'text-[#00723B]'}>
                            {parseFloat(analytics?.totalApplicantsGrowthRate)?.toFixed(2) || 0}% </span> <span>Compared to last month
                        </span>
                    </p>
                </div>
            </div>
            <div 
                onClick={navigateTo.bind(this, '/dashboard/sessions', RESUME_STAGES.VIDEO_PROFILING)}
                className={`${((user && user?.['role'] === 'admin') || config?.enabledServices?.videoProfiling) ? 'visible': 'hidden' } bg-white py-5 px-4 w-1/5 gap-6 flex flex-col rounded-md shadow hover:scale-105 cursor-pointer transition-transform duration-200`}>
                <div className="flex items-center justify-between">
                    <div className="flex flex-col gap-1">
                        <span className='text-[#999999] font-normal text-sm'>Video Profiling</span>
                        <h1 className='text-[#161616] text-3xl font-bold'>{analytics?.totalVideoProfiling || 0}</h1>
                    </div>
                    <img alt='icon' src={exitInterviewIcon} className='h-10 w-10' />
                </div>
                <div className='flex items-center gap-2'>
                    <img
                        alt='icon'  
                        className={`h-8 w-8 ${analytics?.totalVideoProfilingGrowthRate !== 0  ? 'visible': 'hidden'}`}
                        src={analytics?.totalVideoProfilingGrowthRate < 0 ? declineIcon : growthIcon}
                    />
                    <div className={`h-8 w-8 rounded-full bg-lightBlue flex justify-center items-center 
                        ${analytics?.totalVideoProfilingGrowthRate === 0 ? 'visible': 'hidden'}`}>
                        <FaEquals size={15} color='#3C99CA'/>
                    </div>
                    <p className='text-[#999999] text-sm font-normal'>
                        <span className={analytics?.totalVideoProfilingGrowthRate < 0 ? 'text-[#FF3B30]': 'text-[#00723B]'}>
                            {parseFloat(analytics?.totalVideoProfilingGrowthRate)?.toFixed(2) || 0}% </span> <span>Compared to last month
                        </span>
                    </p>
                </div>
            </div>
            <div 
                onClick={navigateTo.bind(this, '/dashboard/sessions', RESUME_STAGES.INITIAL_SCREENING)}
                className={`${(user && user?.['role'] === 'admin') || config?.enabledServices?.hrScreening ? 'visible': 'hidden' } bg-white py-5 px-4 w-1/5 gap-6 flex flex-col rounded-md shadow hover:scale-105 cursor-pointer transition-transform duration-200`}>
                <div className="flex items-center justify-between">
                    <div className="flex flex-col gap-1">
                        <span className='text-[#999999] font-normal text-sm'>Initial HR Interview</span>
                        <h1 className='text-[#161616] text-3xl font-bold'>{analytics?.totalHRInterviews}</h1>
                    </div>
                    <img alt='icon' src={initialInterviewIcon}className='h-10 w-10'
                    />
                </div>
                <div className='flex items-center gap-2'>
                    <img
                        alt='icon'  
                        className={`h-8 w-8 ${analytics?.totalHRInterviewsGrowthRate !== 0  ? 'visible': 'hidden'}`}
                        src={analytics?.totalHRInterviewsGrowthRate < 0 ? declineIcon : growthIcon}
                    />
                    <div className={`h-8 w-8 rounded-full bg-lightBlue flex justify-center items-center 
                        ${analytics?.totalHRInterviewsGrowthRate === 0 ? 'visible': 'hidden'}`}>
                        <FaEquals size={15} color='#3C99CA' />
                    </div>
                    <p className='text-[#999999] text-sm font-normal'>
                        <span className={analytics?.totalHRInterviewsGrowthRate < 0 ? 'text-[#FF3B30]': 'text-[#00723B]'}>
                            {parseFloat(analytics?.totalHRInterviewsGrowthRate)?.toFixed(2) || 0}% </span> <span>Compared to last month
                        </span>
                    </p>
                </div>
            </div>
            <div 
                onClick={navigateTo.bind(this, '/dashboard/sessions', RESUME_STAGES.TECHNICAL_SCREENING)}
                className={`${(user && user?.['role'] === 'admin') || config?.enabledServices?.technicalScreening ? 'visible': 'hidden' } bg-white py-5 px-4 w-1/5 gap-6 flex flex-col rounded-md shadow hover:scale-105 cursor-pointer transition-transform duration-200`}>
                <div className="flex items-center justify-between">
                    <div className="flex flex-col gap-1">
                        <span className='text-[#999999] font-normal text-sm'>Technical Interview</span>
                        <h1 className='text-[#161616] text-3xl font-bold'>{analytics?.totalTechnicalInterviews}</h1>
                    </div>
                    <img alt='icon' src={technicalInterviewIcon} className='h-10 w-10'/>
                </div>
                <div className='flex items-center gap-2'>
                    <img
                        alt='icon'  
                        className={`h-8 w-8 ${analytics?.totalTechnicalInterviewsGrowthRate !== 0 ? 'visible': 'hidden'}`}
                        src={analytics?.totalTechnicalInterviewsGrowthRate < 0 ? declineIcon : growthIcon}
                    />
                    <div className={`h-8 w-8 rounded-full bg-lightBlue flex justify-center items-center 
                        ${analytics?.totalTechnicalInterviewsGrowthRate === 0 ? 'visible': 'hidden'}`}>
                        <FaEquals size={15} color='#3C99CA'/>
                    </div>
                    <p className='text-[#999999] text-sm font-normal'>
                        <span className={analytics?.totalTechnicalInterviewsGrowthRate < 0 ? 'text-[#FF3B30]': 'text-[#00723B]'}>
                            {parseFloat(analytics?.totalTechnicalInterviewsGrowthRate)?.toFixed(2) || 0}% </span> <span>Compared to last month
                        </span>
                    </p>
                </div>
            </div>
            {/* <div 
                onClick={navigateTo.bind(this, '/dashboard/sessions', RESUME_STAGES.EXIT_INTERVIEW)}
                className={`${config?.enabledServices?.exitInterview ? 'visible': 'hidden' } bg-white py-5 px-4 w-1/5 gap-6 flex flex-col rounded-md shadow hover:scale-105 cursor-pointer transition-transform duration-200`}>
                <div className="flex items-center justify-between">
                    <div className="flex flex-col gap-1">
                        <span className='text-[#999999] font-normal text-sm'>Exit Interview</span>
                        <h1 className='text-[#161616] text-3xl font-bold'>{analytics?.totalExitInterviews || 0}</h1>
                    </div>
                    <img alt='icon' src={exitInterviewIcon} className='h-10 w-10' />
                </div>
                <div className='flex items-center gap-2'>
                    <img
                        alt='icon'  
                        className={`h-8 w-8 ${analytics?.totalExitInterviewsGrowthRate !== 0  ? 'visible': 'hidden'}`}
                        src={analytics?.totalExitInterviewsGrowthRate < 0 ? declineIcon : growthIcon}
                    />
                    <div className={`h-8 w-8 rounded-full bg-lightBlue flex justify-center items-center 
                        ${analytics?.totalExitInterviewsGrowthRate === 0 ? 'visible': 'hidden'}`}>
                        <FaEquals size={15} color='#3C99CA'/>
                    </div>
                    <p className='text-[#999999] text-sm font-normal'>
                        <span className={analytics?.totalExitInterviewsGrowthRate < 0 ? 'text-[#FF3B30]': 'text-[#00723B]'}>
                            {parseFloat(analytics?.totalExitInterviewsGrowthRate)?.toFixed(2) || 0}% </span> <span>Compared to last month
                        </span>
                    </p>
                </div>
            </div> */}
            <div 
                onClick={navigateTo.bind(this, '/dashboard/sessions', RESUME_STAGES.BPO_HR_SCREENING)}
                className={`${(user && user?.['role'] === 'admin') || config?.enabledServices?.bpoHrScreening ? 'visible': 'hidden' } bg-white py-5 px-4 w-1/5 gap-6 flex flex-col rounded-md shadow hover:scale-105 cursor-pointer transition-transform duration-200`}>
                <div className="flex items-center justify-between">
                    <div className="flex flex-col gap-1">
                        <span className='text-[#999999] font-normal text-sm'>BPO HR Interview</span>
                        <h1 className='text-[#161616] text-3xl font-bold'>{analytics?.totalBpoHRInterviews || 0}</h1>
                    </div>
                    <img alt='icon' src={exitInterviewIcon} className='h-10 w-10' />
                </div>
                <div className='flex items-center gap-2'>
                    <img
                        alt='icon'  
                        className={`h-8 w-8 ${analytics?.totalBpoHRInterviewsGrowthRate !== 0  ? 'visible': 'hidden'}`}
                        src={analytics?.totalBpoHRInterviewsGrowthRate < 0 ? declineIcon : growthIcon}
                    />
                    <div className={`h-8 w-8 rounded-full bg-lightBlue flex justify-center items-center 
                        ${analytics?.totalBpoHRInterviewsGrowthRate === 0 ? 'visible': 'hidden'}`}>
                        <FaEquals size={15} color='#3C99CA'/>
                    </div>
                    <p className='text-[#999999] text-sm font-normal'>
                        <span className={analytics?.totalBpoHRInterviewsGrowthRate < 0 ? 'text-[#FF3B30]': 'text-[#00723B]'}>
                            {parseFloat(analytics?.totalBpoHRInterviewsGrowthRate)?.toFixed(2) || 0}% </span> <span>Compared to last month
                        </span>
                    </p>
                </div>
            </div>
            <div 
                onClick={navigateTo.bind(this, '/dashboard/sessions', RESUME_STAGES.OPS_SCREENING)}
                className={`${(user && user?.['role'] === 'admin') || config?.enabledServices?.opsScreening ? 'visible': 'hidden' } bg-white py-5 px-4 w-1/5 gap-6 flex flex-col rounded-md shadow hover:scale-105 cursor-pointer transition-transform duration-200`}>
                <div className="flex items-center justify-between">
                    <div className="flex flex-col gap-1">
                        <span className='text-[#999999] font-normal text-sm'>OPS Interview</span>
                        <h1 className='text-[#161616] text-3xl font-bold'>{analytics?.totalOpsInterviews || 0}</h1>
                    </div>
                    <img alt='icon' src={exitInterviewIcon} className='h-10 w-10' />
                </div>
                <div className='flex items-center gap-2'>
                    <img
                        alt='icon'  
                        className={`h-8 w-8 ${analytics?.totalOpsInterviewsGrowthRate !== 0  ? 'visible': 'hidden'}`}
                        src={analytics?.totalOpsInterviewsGrowthRate < 0 ? declineIcon : growthIcon}
                    />
                    <div className={`h-8 w-8 rounded-full bg-lightBlue flex justify-center items-center 
                        ${analytics?.totalOpsInterviewsGrowthRate === 0 ? 'visible': 'hidden'}`}>
                        <FaEquals size={15} color='#3C99CA'/>
                    </div>
                    <p className='text-[#999999] text-sm font-normal'>
                        <span className={analytics?.totalOpsInterviewsGrowthRate < 0 ? 'text-[#FF3B30]': 'text-[#00723B]'}>
                            {parseFloat(analytics?.totalOpsInterviewsGrowthRate)?.toFixed(2) || 0}% </span> <span>Compared to last month
                        </span>
                    </p>
                </div>
            </div>
        </div>
    )
}

HRDashboardAnalyticsSection.propTypes = {
    handleSelectedJd: PropTypes.func,
    selectedYear: PropTypes.string,
    handleMenuVisibility: PropTypes.func
}

export default HRDashboardAnalyticsSection;