import {BounceLoader} from 'react-spinners';
import { useNavigate } from 'react-router-dom';
import { memo, useEffect, useState } from 'react';
import logo from './../../assets/icons/white-logo.svg';
import { RESUME_STAGES } from '../../utils/constants';
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { useToast } from '../../contextProviders/ToastContext';
import { MdVideocam, MdMic, MdScreenShare } from "react-icons/md";
import { useBotImages } from '../../contextProviders/BotImageContext';
import backgroundImage1 from './../../assets/images/background1.svg';
import backgroundImage2 from './../../assets/images/background2.svg';
import { useAnomalies } from '../../contextProviders/AnomaliesContext';
import { useMediaTest } from '../../contextProviders/MediaTestContext';
import { useSessionContext } from '../../contextProviders/SessionContext';
import { useMediaContext } from '../../contextProviders/InterviewMediaContext';
import PermissionsBlockedPopup from '../../components/Common/PermissionsBlockedPopup';
import MultiMonitorDetectedPopup from '../../components/Common/MultiMonitorDetectedPopup';
import InterviewDevicesSelectionComponent from '../../components/Interview/InterviewDevicesSectionComponent';

const InterviewLanding = memo(() => {
    const navigate = useNavigate();
    const {showErrorMessage} = useToast();
    const { updateAnomalies } = useAnomalies();
    const [permissionsPopupVisibility, setPermissionsPopupVisibility] = useState(false);
    const {downloadImages, botImages, loadingBotImages} = useBotImages();

    const {
        microphoneStatus,
        cameraStatus,
    } = useMediaTest();


    const {
        sessionInfo,
        setConnection,
        showInfoPopup,
        setShowInfoPopup,
        clearTranscripts,
        setIsFaceDetected,
        clearMessageQueue,
        setIsPermissionsVerified
    } = useSessionContext();
    const {  
        videoStream, 
        audioStream,
        displayStream, 
        setDisplayStream, 
    } = useMediaContext();

    const { 
        requestMicrophonePermission,
        requestCameraPermission,
        requestScreeningSharingPermission
    } = useMediaTest();

    const requestPermissions = async () => {
        if(!videoStream?.active) await requestCameraPermission();
        if(!audioStream?.active) await requestMicrophonePermission();
        if(!displayStream?.active) await requestScreeningSharingPermission();
    }

    useEffect(() => {
        setIsFaceDetected(false);
        updateAnomalies({ face_missing: 0});
    }, []);

    useEffect(() => {
        if(microphoneStatus !== "denied" && cameraStatus !== "denied"){
            if(permissionsPopupVisibility)
                setPermissionsPopupVisibility(false)
        }
    }, [microphoneStatus, cameraStatus])

    useEffect(() => {
        async function init(){
            if(videoStream?.active && audioStream?.active && displayStream?.active){
                const screenType = displayStream.getVideoTracks()[0].getSettings().displaySurface;
                if(screenType === "monitor"){
                    setPermissionsPopupVisibility(false);
                    setIsPermissionsVerified(true);
                    if(Object.keys(botImages)?.length === 0)
                        await downloadImages();
                    else if (Object.keys(botImages)?.length > 0 && !loadingBotImages)
                        navigate(`/interview/room/${localStorage.getItem('key')}`)
                }
                else{
                    if(displayStream && displayStream.active)
                        displayStream.getTracks().forEach(track => track.stop());
                    setDisplayStream(displayStream);
                    showErrorMessage({
                        life: 5000, 
                        summary: 'Screening sharing issue', 
                        detail: 'Please share your full screen' 
                    })
                }
            }
        }

        init();
        // else if(!isFaceDetected && videoStream?.active){
        //     showErrorMessage({
        //         life: 2000, 
        //         summary: 'Face Not Detected', 
        //         detail: "We can't see your face. Please ensure you're in frame and well-lit" 
        //     })
        // }

    }, [audioStream, videoStream, displayStream, loadingBotImages, Object.keys(botImages)?.length]);



    const joinInterview = async () => {
        setConnection(null);
        clearTranscripts();
        clearMessageQueue();
        await requestPermissions();
        if(microphoneStatus === "denied" || cameraStatus === "denied"){
            if(microphoneStatus === "denied")
                await requestMicrophonePermission();
            if(cameraStatus === "denied")
                await requestCameraPermission();
            setPermissionsPopupVisibility(true);
        }
        // if(!isFaceDetected && videoStream?.active){
        //     showErrorMessage({
        //         life: 2000, 
        //         summary: 'Face Not Detected', 
        //         detail: "We can't see your face. Please ensure you're in frame and well-lit" 
        //     })
        // }
        // else if(videoStream?.active && audioStream?.active && displayStream?.active && isFaceDetected){
        //     const screenType = displayStream.getVideoTracks()[0].getSettings().displaySurface;
        //     if(screenType === "monitor"){
        //         navigate(`/interview/room?token=${sessionInfo?.token || token}`)
        //     }
        // }
    }

    function getInterviewStageLabel(interviewType) {
        switch (interviewType) {
            case RESUME_STAGES.INITIAL_SCREENING:
            case RESUME_STAGES.BPO_HR_SCREENING:
                return 'Initial Screening';
            case RESUME_STAGES.VIDEO_PROFILING:
                return 'Video Profiling';
            case RESUME_STAGES.TECHNICAL_SCREENING:
                return 'Technical Assessment';
            default:
                return 'OPS Interview';
        }
    }

    return (
        <div className="min-h-screen w-screen bg-primary flex justify-center items-center py-5">
            <img
                alt="background" 
                src={backgroundImage1}
                className="absolute bottom-0 right-0"
            />
            <img
                alt="background" 
                src={backgroundImage2}
                className="absolute top-2 left-0"
            />
            <PermissionsBlockedPopup
                visible={permissionsPopupVisibility} 
                hide={() => setPermissionsPopupVisibility(false)}
            />
            <div className='flex 3xl:h-[90%] w-[90%] flex-col gap-3 2xl:gap-7'>
                <div className="flex justify-end">
                    <img 
                        src={logo}
                        alt='logo'
                        className='h-10 3xl:h-12'
                    />
                </div>
                <MultiMonitorDetectedPopup />
                <div className='flex gap-10 min-h-[95%] w-full'>
                    <div className='bg-white w-2/3 rounded-xl justify-between flex flex-col p-10'>
                        <div className='flex flex-col'>
                            <h2 className='font-semibold text-primary text-base xl:text-xl text-center'>
                                Welcome to the {getInterviewStageLabel(sessionInfo?.interview_type)}
                            </h2>
                            <div className='flex flex-col w-full py-10 3xl:py-20'>
                                <div className='flex w-full xl:gap-3 3xl:gap-5'>
                                    <IoIosArrowDroprightCircle 
                                        size={window.screen.width > 1500 ? 28 : 24}
                                        color='#2B4360'
                                    />
                                    <div className='w-full'>
                                        <div className='flex items-center gap-1 text-xs 3xl:text-sm'>
                                            <h4 className='text-primary font-semibold'>Job Title :</h4>
                                            <span className='text-blue'>{sessionInfo?.job_title}</span>
                                        </div>
                                        <hr className='my-6 border-0 border-b-[.2px]'/>
                                    </div>
                                </div>
                                <div className='flex w-full xl:gap-3 3xl:gap-5'>
                                    <IoIosArrowDroprightCircle 
                                         size={window.screen.width > 1500 ? 28 : 24}
                                        color='#2B4360'
                                    />
                                    <div className='w-full'>
                                        <div className='flex items-center gap-1 text-xs 3xl:text-sm'>
                                            <h4 className='text-primary font-semibold'>Duration :</h4>
                                            <span className='text-blue'>{Math.ceil(sessionInfo?.interview_duration / 60)} minutes</span>
                                        </div>
                                        <hr className='my-6 border-0 border-b-[.2px]'/>
                                    </div>
                                </div>
                                <div className='flex w-full xl:gap-3 3xl:gap-5'>
                                    <IoIosArrowDroprightCircle 
                                         size={window.screen.width > 1500 ? 28 : 24}
                                        color='#2B4360'
                                    />
                                    <div className='w-full'>
                                        <div className='flex items-center gap-1 text-xs 3xl:text-sm'>
                                            <h4 className='text-primary font-semibold'>Format: </h4>
                                            <span className='text-blue'>Athmiya will interact with you through out the screening.</span>
                                        </div>
                                        <hr className='my-6 border-0 border-b-[.2px]'/>
                                    </div>
                                </div>
                                <div className='flex w-full xl:gap-3 3xl:gap-5'>
                                    <IoIosArrowDroprightCircle 
                                         size={window.screen.width > 1500 ? 28 : 24}
                                        color='#2B4360'
                                    />
                                    <div className='w-full'>
                                        <div className='flex items-center gap-1 text-xs 3xl:text-sm'>
                                            <h4 className='text-primary font-semibold'>Permissons required: </h4>
                                        </div>
                                        <ul className='py-5 text-xs 3xl:text-sm text-brownGray flex flex-col gap-4'>
                                            <li className='flex items-center gap-4'>
                                                <MdMic size={18}/>
                                                <p>Microphone Permisson</p>
                                            </li>
                                            <li className='flex items-center gap-4'>
                                                <MdScreenShare size={18}/>
                                                <p>Screen sharing permisson</p>
                                            </li>
                                            <li className='flex items-center gap-4'>
                                                <MdVideocam size={18}/>
                                                <p>Camera Permissons</p>
                                            </li>
                                        </ul>
                                        {/* <hr className='my-6 border-0 border-b-[.2px]'/> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className='text-primary font-normal text-sm 3xl:text-base text-center'>
                            We look forward for an engaging and interactive session
                        </p>
                    </div>
                    <div className='bg-white flex rounded-xl'>
                        <InterviewDevicesSelectionComponent />
                    </div>
                </div>
                <button 
                    onClick={joinInterview}
                    className='h-9 3xl:h-12 bg-blue w-32 3xl:w-40 m-auto rounded flex justify-center items-center text-white font-semibold text-xs 3xl:text-sm'>
                    {loadingBotImages && <BounceLoader size={26} color="#ffffff" /> }
                    <span className={`font-semibold text-xs ${!loadingBotImages ? 'visible': 'hidden'}`}>Continue</span>
                </button>
            </div>
            <div className={`h-screen w-screen bg-transparent absolute left-0 top-0 z-40 justify-center items-center flex ${showInfoPopup ? 'visible': 'hidden'}`}>
                <div className='bg-white p-5 rounded-md'>
                    <h2 className='text-primary font-bold text-xl 3xl:text-xl'>Before You Join the Interview</h2>
                    <div className='pt-3 3xl:pt-5 font-normal flex flex-col gap-4 mt-2 justify-center text-darkGray'>
                        <div className='pb-10 pt-2'>
                            <p className='text-base font-semibold'>Please ensure the following:</p>
                            <ul className='text-sm divide-dotted list-disc px-5 flex flex-col gap-1 font-medium mt-2'>
                                <li>You are in a quiet space with a stable internet connection.</li>
                                <li>Your microphone is working and you can be heard clearly.</li>
                                <li>You have allotted sufficient uninterrupted time for the entire duration of the interview.</li>
                                <li>Your camera is ON and your face is fully visible. Failure to comply may invalidate the interview.</li>
                            </ul>
                        </div>
                        <button
                            onClick={() => setShowInfoPopup(false)} 
                            className='h-9 3xl:h-10 bg-blue text-white w-20 m-auto rounded-md font-semibold text-xs 3xl:text-sm'>
                            GOT IT
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default InterviewLanding;
