import { useEffect, useState } from 'react';
import {BounceLoader} from 'react-spinners';
import { RESUME_STAGES } from '../../utils/constants';
import { MdKeyboardArrowLeft } from "react-icons/md";
import ConsoleHelper from '../../utils/consoleHelper';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SessionService from '../../services/session.service';
import ReportOpsScreening from '../Report/ReportOpsScreening';
import ReportInitialScreening from '../Report/ReportInitalScreening';
import ReportTechnicalScreening from '../Report/ReportTechnicalScreening';
import ReportResumeScreening from '../Report/ReportResumeScreening';
import ReportVideoProfiling from '../Report/ReportVideoProfiling';
import ReportBpoHrScreening from '../Report/ReportBpoHrScreening';

export default function SessionPage(){
    const {id} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const state = location?.state;

    const interviewType = state?.interviewType;

    const [report, setReport] = useState({});
    const [loading, setLoading] = useState(false);

    const goBack = () => navigate(-1);

    useEffect(() => {
        setLoading(true);
        async function init(){
            try {
                const response = await SessionService.getSession(id, interviewType);
                setLoading(false);
                if(response.status === 200){
                    const data = response['data'];
                    setReport(data?.['data'] || {})
                }
            } catch (error) {
                console.log(error)
                setLoading(false);
                ConsoleHelper.log(JSON.stringify(error));
            }
        }
        init();
    }, [])

    if(loading){
        return (
            <div className="justify-center items-center flex">
                <BounceLoader size={40} color="#2B4360"/>
            </div>
        )
    }
    
    return (
        <div className="h-screen p-5">
            <div className='flex flex-col gap-5 sticky top-0 z-40 bg-[#ECF5FA] pb-5'>
                <div className="flex flex-row items-center justify-between w-full">
                    <div 
                        onClick={goBack}
                        className="flex items-center gap-1 text-[#161616] cursor-pointer">
                        <MdKeyboardArrowLeft size={26}/>
                        <h1 className='text-xl text-primary font-bold'>Report</h1>
                    </div>
                </div>
                <p className=' text-brownGray text-xl'>Interview report of 
                    <span className='text-darkGray font-semibold px-2'>{state?.candidate?.['Name'] || report?.candidate?.['Name']}</span> for 
                    <span className='text-darkGray font-semibold px-2'>{state?.jobTitle || report?.jobTitle}</span>  role
                </p>
            </div>
            <div className='bg-white p-5 pt-10 rounded-md text-sm font-normal text-primary'>
                {(report?.['screeningType'] === RESUME_STAGES.INITIAL_SCREENING || report?.['screeningType'] === RESUME_STAGES.INITIAL_SCREENING) && <ReportInitialScreening report={report}  />}
                {report?.['screeningType'] === RESUME_STAGES.TECHNICAL_SCREENING  && <ReportTechnicalScreening report={report} />}
                {report?.['screeningType'] === RESUME_STAGES.OPS_SCREENING  && <ReportOpsScreening report={report} />}
                {report?.['screeningType'] === RESUME_STAGES.BPO_HR_SCREENING  && <ReportBpoHrScreening report={report} />}
                {report?.['screeningType'] === RESUME_STAGES.VIDEO_PROFILING  && <ReportVideoProfiling report={report} />}
                {!report?.['screeningType']  && <ReportResumeScreening report={report} />}
            </div>
        </div>
    )
}

