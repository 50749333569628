import moment from "moment";
import React from "react";
import Lottie from 'react-lottie';
import * as maintenanceJson from "./../assets/animations/maintenance.json";
import { useNavigate } from "react-router-dom";

export default function MaintenancePage(props){
    const navigate = useNavigate();

    const navigateTo = (path) => {
        navigate(path);
    }

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: maintenanceJson,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className="flex flex-col items-center gap-10 justify-center h-screen">
            <h1 className="text-[1.8rem] font-semibold text-blue mb-4">Under Maintenance</h1>
            <div className="text-blue-500 mb-6">
                <Lottie 
                    height={window.screen.height * .4}
                    width={window.screen.width * .4}
                    options={defaultOptions}
                />
            </div>
            <p className="text-center px-4 w-1/2 tracking-wider text-darkGray">
                Our platform is currently undergoing scheduled maintenance. We expect to
                be back by <span className="font-semibold">{moment(props?.['waitTime']).format('DD MMM yyyy hh:mm:ss')}</span>.
                Thank you for your patience!
            </p>
            <button 
                onClick={navigateTo.bind(this, '/')}
                className="px-6 py-2 bg-primary text-white font-medium rounded-lg shadow-md hover:scale-105 hover:bg-blue transition">
                Go to home page
            </button>
        </div>
    );
}