import { useContext, useEffect, useState } from "react";

import Sitemap from "../pages/Sitemap";
import NotFound from "../pages/NotFound";

import HRRoutes from "./HRRoutes";
import AuthRoutes from "./AuthRoutes";
import AdminRoutes from "./AdminRoutes";
import BlogRoutes from "./BlogRoutes";
import HRAdminRoutes from "./HRAdminRoute";
import InterviewRoutes from "./InterviewRoutes";
import Layout from "../components/Common/Layout";
import LandingPage from "../pages/LandingPage";
import ProductsPage from "../pages/ProductsPage";
import SolutionPage from "../pages/SolutionPage";
import AboutUsPage from "../pages/AboutUsPage";
import BookDemoPage from "../pages/BookDemoPage";

import ROLES from "../constants/roles";
import TrialRequestPage from "../pages/TrialRequestPage";
import MaintenancePage from "../pages/MaintenancePage";
import { useAuth } from "../contextProviders/AuthContext";
import TermsOfServicePage from "../pages/TermsAndServicePage";
import { ConfigProvider } from "../contextProviders/ConfigContext";
import LoadingComponent from "../components/Common/LoadingComponent";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { PageDataProvider } from "../contextProviders/PagesDataContext";
import { MaintenanceContext } from "../contextProviders/MaintenanceContext";

export default function RootRoutes() {
    const { user } = useAuth();
    const location = useLocation();
    const {pathname} = useLocation();
    const [loading, setLoading] = useState(true);
    const [userRole, setUserRole] = useState(null);
    const {isMaintenance, waitTime} = useContext(MaintenanceContext);
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    const showMaintenancePage = 
        user?.role !== 'admin' && isMaintenance && 
        (pathname?.includes('/auth') || pathname?.includes('/interview') || pathname?.includes('/dashboard'));
  
    // Scroll to top on route change
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    // Simulate loading and set user authentication and role
    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(false);
            if (!location.pathname.includes("interview")) {
                setIsAuthenticated(!!user);
                setUserRole(user?.role || null);
            }
        }, 100);
        return () => clearTimeout(timeout);
    }, [user, location.pathname]);

    // Render mobile warning for specific routes
    if (window.screen.width <= 850 && ["interview", "auth", "dashboard"].some(path => window.location.pathname.includes(path))) {
        return (
            <div className="h-screen bg-primary flex flex-col items-center justify-center text-center px-2 text-base font-normal gap-4">
                <p className="text-white">This website is best viewed on a desktop.</p>
                <p className="text-white">Please switch to a larger device for the best experience.</p>
            </div>
        );
    }

    if (loading || isMaintenance === null) {
        return (
            <div className="h-screen w-screen flex justify-center items-center">
                <LoadingComponent />
            </div>
        );
    }

    if(showMaintenancePage) return <MaintenancePage waitTime={waitTime} />

    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<LandingPage />} />
                <Route path="products" element={<ProductsPage />} />
                <Route path="solution" element={<SolutionPage />} />
                <Route path="about-us" element={<AboutUsPage />} />
                <Route path="demo" element={<BookDemoPage />} />
                <Route path="blogs/*" element={<BlogRoutes />} />
                <Route path="try-for-free" element={<TrialRequestPage />} />
                <Route path="*" element={<LandingPage />} />
            </Route>

            <Route path="interview/*" element={<InterviewRoutes />} />

            <Route
                path="auth/*"
                element={!isAuthenticated ? <AuthRoutes /> : <Navigate to="/dashboard" />}
            />

            <Route
                path="dashboard/*"
                element={
                    !isAuthenticated ? <Navigate to="/auth/login" /> : 
                    (
                        <ConfigProvider>
                            <PageDataProvider>
                                {userRole === ROLES.HR && <HRRoutes />}
                                {userRole === ROLES.HRADMIN && <HRAdminRoutes />}
                                {userRole === ROLES.ADMIN && <AdminRoutes />}
                                {![ROLES.HR, ROLES.HRADMIN, ROLES.ADMIN].includes(userRole) && <NotFound />}
                            </PageDataProvider>
                        </ConfigProvider>
                    )
                }
            />

            <Route
                path="terms-service"
                element={!isAuthenticated ? <AuthRoutes /> : 
                (isAuthenticated && (user && !user?.['isTermsAccepted'])) ? 
                    <TermsOfServicePage /> : <Navigate to="/dashboard" />}
            />

            <Route path="notfound" element={<NotFound />} />
            <Route path="sitemap.xml" element={<Sitemap />} />
        </Routes>
    );
}
