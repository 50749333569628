// MaintenanceContext.js
import React, { createContext, useState, useEffect } from "react";
import MaintenanceService from "../services/maintenance.service";
import ConsoleHelper from "../utils/consoleHelper";

export const MaintenanceContext = createContext();

export const MaintenanceProvider = ({ children }) => {
  const [isMaintenance, setIsMaintenance] = useState(null);
  const [waitTime, setWaitTime] = useState(null);

  useEffect(() => {
    const fetchMaintenanceStatus = async () => {
      try {
        const response = await MaintenanceService.getMaintenanceInfo();
        if(response.status === 200){
            const data = response.data;
            setIsMaintenance(data.maintenanceMode);
            setWaitTime(data.waitTime);
        }
      } catch (error) {
        ConsoleHelper.error(`Failed to fetch maintenance status ${JSON.stringify(error)}`);
      }
    };

    fetchMaintenanceStatus();
  }, []);

  return (
    <MaintenanceContext.Provider value={{ isMaintenance, waitTime }}>
      {children}
    </MaintenanceContext.Provider>
  );
};
