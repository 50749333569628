import axiosInstance from ".";

class MaintenanceService {
    getMaintenanceInfo(){
        return axiosInstance.get('/maintenances');
    }

    updateMaintenanceInfo(data){
        return axiosInstance.post('/maintenances', data);
    }
}

const object = new MaintenanceService();
export default object;