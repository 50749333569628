import React, { useState } from 'react';
import { BounceLoader } from 'react-spinners';
import AuthService from '../services/auth.service';
import { useAuth } from '../contextProviders/AuthContext';
import { useToast } from '../contextProviders/ToastContext';

function TermsOfServicePage() {
    const { showWarningMessage } = useToast();
    const [loading, setLoading] = useState(false);
    const {setUser, user} = useAuth();

    const handleAccept = async () => {
        try {
            setLoading(true);
            const requestBody = {isTermsAccepted: true}
            const response = await AuthService.acceptTermsAndService(requestBody);
            setLoading(false);
            if(response.status === 200){
                const data = {...user, isTermsAccepted: true};
                setUser(data);
                localStorage.setItem('user', JSON.stringify(data));
            }
        } catch (error) {
            setLoading(false);
            showWarningMessage({ life: 3000, summary: 'Failed', detail: 'Failed to accept the terms'})
        }
    };

  return (
    <div className='bg-transparent py-5 flex justify-center items-center'>
        <div className="p-5 flex flex-col gap-5 rounded-lg h-[99%] w-[80%] bg-white text-darkGray shadow-md drop-shadow-md overflow-y-scroll">
        <h1 className='text-2xl font-bold text-center text-blue'>ATHMICK TERMS OF SERVICE</h1>
        <p className='text-sm'>
          Welcome to the Athmick platform! By accessing or using the platform, you agree to comply
          with and be bound by these Terms of Service (“Terms”). If you do not agree with these
          Terms, please do not use the platform.
        </p>

        <div>
          <h2 className='font-bold text-primary'>1. ACCEPTANCE OF TERMS</h2>
          <ul className="list-disc px-5 flex flex-col gap-1">
              <li className='text-sm'>You are authorized to accept these Terms on behalf of the organization you represent.</li>
              <li className='text-sm'>You agree to comply with these Terms and any applicable laws.</li>
          </ul>
        </div>

        <div>
          <h2 className='font-bold text-primary'>2. SCOPE OF SERVICES</h2>
          <p className='text-sm'>
              Athmick provides AI-driven recruitment solutions, including but not limited to resume screening,
              candidate evaluation, interview scheduling, and report generation. All services are provided in
              accordance with the terms of your agreement with Athmick.
          </p>
        </div>

        <div>
          <h2 className='font-bold text-primary'>3. ACCOUNT RESPONSIBILITIES</h2>
          <ul className="list-disc px-5 flex flex-col gap-1">
              <li className='text-sm'>You are responsible for maintaining the confidentiality of your login credentials.</li>
              <li className='text-sm'>You must immediately notify Athmick of any unauthorized access or suspected security breaches.</li>
              <li className='text-sm'>You agree not to share your account access with third parties or use the platform for any illegal purposes.</li>
          </ul>
        </div>

        <div>
          <h2 className='font-bold text-primary'>4. USAGE RESTRICTIONS</h2>
          <ul className="list-disc px-5 flex flex-col gap-1">
              <li className='text-sm'>Not to reverse engineer, decompile, or disassemble the platform or its underlying technology.</li>
              <li className='text-sm'>Not to misuse or overload the platform, including through automated scripts or bots.</li>
              <li className='text-sm'>To use the platform solely for lawful purposes and in accordance with applicable data protection laws.</li>
          </ul>
        </div>

        <div>
            <h2 className='font-bold text-primary'>5. DATA PRIVACY AND SECURITY</h2>
        <ul className="list-disc px-5 flex flex-col gap-1">
            <li className='text-sm'><strong>User Data:</strong> Athmick processes data uploaded to the platform in compliance with applicable laws.</li>
            <li className='text-sm'><strong>Retention:</strong> Candidate interview videos will be stored for 60 days post-interview.</li>
            <li className='text-sm'><strong>Confidentiality:</strong> Athmick implements industry-standard encryption and security measures.</li>
        </ul>
        </div>

      <div>
        <h2 className='font-bold text-primary'>6. INTELLECTUAL PROPERTY</h2>
        <p className='text-sm'>
            All intellectual property rights in the platform, including its design, software, and algorithms, remain
            the sole property of Athmick. Users are granted a limited, non-exclusive, and non-transferable license
            to use the platform as per the agreed terms.
        </p>
      </div>

      <div>

      <h2 className='font-bold text-primary'>7. PAYMENT AND BILLING</h2>
      <ul className="list-disc px-5 flex flex-col gap-1">
          <li className='text-sm'>All payments are subject to the pricing and billing terms outlined in your service agreement.</li>
          <li className='text-sm'>Taxes, including GST, may be applicable and are the responsibility of the user.</li>
      </ul>
      </div>

      <div>
      <h2 className='font-bold text-primary'>8. LIMITATION OF LIABILITY</h2>
    <p className='text-sm'>
      Athmick is not liable for any indirect, incidental, or consequential damages arising from the use
      or inability to use the platform. Athmick’s total liability shall not exceed the fees paid for the
      platform in the preceding three (3) months.
    </p>
      </div>
      <div>
      <h2 className='font-bold text-primary'>9. TERMINATION</h2>
        <ul className="list-disc px-5 flex flex-col gap-1">
          <li className='text-sm'>Athmick reserves the right to suspend or terminate access to the platform in case of violation of these Terms.</li>
          <li className='text-sm'>Upon termination, your access will be revoked, and all data will be handled per the Data Retention Policy.</li>
        </ul>
      </div>
      <div>

        <h2 className='font-bold text-primary'>10. MODIFICATIONS</h2>
        <p className='text-sm'>
          Athmick reserves the right to modify these Terms at any time. Users will be notified of significant changes,
          and continued use of the platform constitutes acceptance of the updated Terms.
        </p>
      </div>
      <div>

        <h2 className='font-bold text-primary'>11. GOVERNING LAW AND DISPUTE RESOLUTION</h2>
        <p className='text-sm'>
          These Terms shall be governed by the laws of India. Any disputes shall be resolved through arbitration in
          Bangalore, Karnataka, as per the Arbitration and Conciliation Act, 1996.
        </p>
      </div>
      <div>
      <h2 className='font-bold text-primary'>12. CONTACT US</h2>
      <p className='text-sm'>
        If you have any questions or concerns about these Terms, please contact us at:<br />
        <strong>Athmick Private Limited</strong><br />
        Email: <a href="mailto:support@athmick.com">support@athmick.com</a>
      </p>
    </div>
      <div className="flex gap-2 items-center justify-center">
          {/* <CheckboxComponent
              value={isAccepted}
              className="w-6 h-6 border-primary hover:border-primary border-2 hover:text-primary"
              setValue={(k, v) => setIsAccepted(v)} 
          />  */}
          <p className="text-primary">I have read and agree to the platform</p>
          <button
              onClick={handleAccept}
              className="bg-blue h-8 rounded text-white w-28 justify-center flex items-center"
              disabled={loading}
          >
              {loading ? <BounceLoader size={26} color="#ffffff" /> : <span className="font-semibold text-sm">I Accept</span>}
          </button>
      </div>
    </div>
    </div>
  );
}

export default TermsOfServicePage;
